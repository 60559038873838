// @ts-nocheck
import {Link} from "react-router-dom";
import React from "react";
/*import {makeStyles} from '@mui/styles'*/
import HoverMenu from 'material-ui-popup-state/HoverMenu'
import MenuItem from '@mui/material/MenuItem'
import Button from "@mui/material/Button";
import {
  usePopupState,
  bindHover,
  bindFocus,
  bindMenu,
} from 'material-ui-popup-state/hooks'
import './Navigation.css';
/*import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";*/

// https://jcoreio.github.io/material-ui-popup-state/#cascading-hover-menus

/*const useCascadingMenuStyles = makeStyles((theme) => ({
  submenu: {
    marginTop: theme.spacing(-1),
  },
  title: {
    flexGrow: 1,
  },
  moreArrow: {
    marginRight: theme.spacing(-1),
  },
}))*/

const CascadingContext = React.createContext({
  parentPopupState: null,
  rootPopupState: null,
})

function CascadingMenuItem({onClick, ...props}) {
  const {rootPopupState} = React.useContext(CascadingContext)
  if (!rootPopupState) throw new Error('must be used inside a CascadingMenu')
  const handleClick = React.useCallback(
    (event) => {
      rootPopupState.close(event)
      if (onClick) onClick(event)
    },
    [rootPopupState, onClick]
  )

  return <MenuItem {...props} onClick={handleClick}/>
}

/*function CascadingSubmenu({title, popupId, ...props}) {
  const classes = useCascadingMenuStyles()
  const {parentPopupState} = React.useContext(CascadingContext)
  const popupState = usePopupState({
    popupId,
    variant: 'popover',
    parentPopupState,
  })
  return (
    <React.Fragment>
      <MenuItem {...bindHover(popupState)} {...bindFocus(popupState)}>
        <span className={classes.title}>{title}</span>
        <FontAwesomeIcon icon={faChevronRight} className={classes.moreArrow}/>
      </MenuItem>
      <CascadingMenu
        {...props}
        classes={{...props.classes, paper: classes.submenu}}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
        popupState={popupState}
      />
    </React.Fragment>
  )
}*/

function CascadingMenu({popupState, ...props}) {
  const {rootPopupState} = React.useContext(CascadingContext)
  const context = React.useMemo(
    () => ({
      rootPopupState: rootPopupState || popupState,
      parentPopupState: popupState,
    }),
    [rootPopupState, popupState]
  )

  return (
    <CascadingContext.Provider value={context}>
      <HoverMenu {...props} {...bindMenu(popupState)} />
    </CascadingContext.Provider>
  )
}

export function Navigation() {
  const toolsMenu = usePopupState({
    popupId: 'nav-menu_tools',
    variant: 'popover',
  })
  const administrationMenu = usePopupState({
    popupId: 'nav-menu_administration',
    variant: 'popover',
  })
  const configurationMenu = usePopupState({
    popupId: 'nav-menu_configuration',
    variant: 'popover',
  })
  return (
    <nav style={{padding: '0 8px'}}>
      <Button className={'nav-button'}>
        <Link
          color="text.primary"
          to="/"
        >Home
        </Link>
      </Button>

      <Button className={'nav-button'}
        {...bindHover(toolsMenu)}
        {...bindFocus(toolsMenu)}
      >
        Tools
      </Button>
      <CascadingMenu
        popupState={toolsMenu}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        <CascadingMenuItem>
          <Link
            color="text.primary"
            to="/body-order-history"
          >Body Order History
          </Link>
        </CascadingMenuItem>
        <CascadingMenuItem>
          <Link
            color="text.primary"
            to="/files-download"
          >Files Download
          </Link>
        </CascadingMenuItem>
        <CascadingMenuItem>
          <Link
            color="text.primary"
            to="/master-id-password"
          >Master ID Password
          </Link>
        </CascadingMenuItem>
        <CascadingMenuItem>
          <Link
            color="text.primary"
            to="/incoming-orders"
          >Incoming Orders
          </Link>
        </CascadingMenuItem>
      </CascadingMenu>

      <Button className={'nav-button'}
        {...bindHover(administrationMenu)}
        {...bindFocus(administrationMenu)}
      >
        Administration
      </Button>
      <CascadingMenu
        popupState={administrationMenu}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        <CascadingMenuItem>
          <Link
            color="text.primary"
            to="/factories"
          >Factories
          </Link>
        </CascadingMenuItem>
        <CascadingMenuItem>
          <Link
            color="text.primary"
            to="/roles"
          >Roles
          </Link>
        </CascadingMenuItem>
      </CascadingMenu>

      <Button className={'nav-button'}
        {...bindHover(configurationMenu)}
        {...bindFocus(configurationMenu)}
      >
        Configuration
      </Button>
      <CascadingMenu
        popupState={configurationMenu}
        anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
        transformOrigin={{vertical: 'top', horizontal: 'left'}}
      >
        <CascadingMenuItem>
          <Link
            color="text.primary"
            to="/email-notifications"
          >Email notifications
          </Link>
        </CascadingMenuItem>
      </CascadingMenu>
    </nav>
  )
}

export default Navigation