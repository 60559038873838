import React from "react";

const Loader = ({size}: any): JSX.Element => {

  return <div className="lds-ring" {...(size ? {style: {width: `${size}px`, height: `${size}px`, margin: 0}} : {})}>
    <div {...(size ? {style: {width: `${size*0.8}px`, height: `${size*0.8}px`, margin: 0}} : {})}></div>
    <div {...(size ? {style: {width: `${size*0.8}px`, height: `${size*0.8}px`, margin: 0}} : {})}></div>
    <div {...(size ? {style: {width: `${size*0.8}px`, height: `${size*0.8}px`, margin: 0}} : {})}></div>
    <div {...(size ? {style: {width: `${size*0.8}px`, height: `${size*0.8}px`, margin: 0}} : {})}></div>
  </div>
}

export default Loader;