import React, {useEffect, useState} from "react";
import './BodyOrderHistory.css';
import './MasterIDPassword.css';
import {Card, CardBody, CardTitle, Table} from "reactstrap";
import useAxios, {UseAxiosResult} from "axios-hooks";
import Page from "./Page";
import {Autocomplete, List, ListItem, TextField, Typography} from "@mui/material";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import {useClipboard} from "use-clipboard-copy";
import {faCopy} from '@fortawesome/free-regular-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import Button from "@mui/material/Button";
import {ChassisListAutocomplete} from "./BodyOrderHistory";
import {getUniqueListBy} from "./utils";
import {Alert} from "@mui/lab";
import {format, parseISO} from "date-fns";

interface ChassisDTO {
  chassisType: string;
  chassisNo: number;
}

interface MasterIdProps {
  "name": string;
  "password": string;
  "class": string;
}

interface GetMasterIdDTO {
  "id": number;
  "completed": string;
  "started": string;
  "totalTime": string;
  "masterIdProps": MasterIdProps [];
}

const Accordion = styled((props) => (
  // @ts-ignore
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    // expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export function MasterIDPassword() {

  const [currentChassis, setCurrentChassis] = useState<null | ChassisDTO>(null);
  const [expanded, setExpanded] = useState('panel0');
  const [chassisList, setChassisList] = useState<ChassisListAutocomplete[]>([]);
  const [copyClickCoordinates, setCopyClickCoordinates] = useState<any>();
  const [{ data, loading, error }]: UseAxiosResult<ChassisDTO[] | undefined, boolean, any> = useAxios('/bodyorder/chassislist');
  const [{ data: dataDetails, loading: loadingDetails, error: errorDetails }, fetchDetails]: UseAxiosResult<GetMasterIdDTO[] | undefined, boolean, any> = useAxios(`/masterid/getmasterid?chassisType=${currentChassis?.chassisType.trim()}&chassisNo=${currentChassis?.chassisNo}`, { manual: true });
  const [{ data: dataOkchassislist, loading: loadingOkchassislist, error: errorOkchassislist }]: UseAxiosResult<ChassisDTO[] | undefined, boolean, any> = useAxios('/bodyorder/okchassislist');
  const [{ data: dataNokchassislist, loading: loadingNokchassislist, error: errorNokchassislist }]: UseAxiosResult<ChassisDTO[] | undefined, boolean, any> = useAxios('/bodyorder/nokchassislist');
  const [{ data: dataLastchassislist, loading: loadingLastchassislist, error: errorLastchassislist }]: UseAxiosResult<ChassisDTO[] | undefined, boolean, any> = useAxios('/bodyorder/lastchassislist');

  useEffect(() => {
    if (dataOkchassislist && dataNokchassislist && dataLastchassislist && data) {
      setChassisList([
        ...getUniqueListBy(dataOkchassislist.map(c => ({...c, group: 'Last OK Processed', label: `ok_${c.chassisType} ${c.chassisNo}`})), 'label'),
        ...getUniqueListBy(dataNokchassislist.map(c => ({...c, group: 'Last NOK Processed', label: `nok_${c.chassisType} ${c.chassisNo}`})), 'label'),
        ...getUniqueListBy(dataLastchassislist.map(c => ({...c, group: 'Recently Processed', label: `recent_${c.chassisType} ${c.chassisNo}`})), 'label'),
        ...getUniqueListBy(data.map(c => ({...c, group: 'All Processed', label: `all_${c.chassisType} ${c.chassisNo}`})), 'label'),
      ]);
    }
  }, [dataOkchassislist, dataNokchassislist, dataLastchassislist, data])

  useEffect( () => {
    if (currentChassis) {
      fetchDetails();
    }
  }, [currentChassis])

  const clipboard = useClipboard({
    copiedTimeout: 600,
  });

  useEffect( () => {
    if (!clipboard.copied) {
      setCopyClickCoordinates(undefined);
    }
  }, [clipboard.copied])

  const handleCopy = React.useCallback(
    (password) => {
      clipboard.copy(password);
    },
    [clipboard.copy]
  );

  const handleChange = (panel: any) => (event: any, newExpanded: any) => {
    setExpanded(newExpanded ? panel : false);
  };

  return(
    <Page title={'Master ID Password'}>
      <div className="row">
        <div className="col-12 col-md-4 col-xl-3">
          <Card className={'chassis-selector'} style={{marginBottom: '24px'}}>
            <CardBody>
              {loading && <p>Loading...</p>}
              {error && <p>Error!!!</p>}
              {(dataNokchassislist && dataLastchassislist && dataOkchassislist && data) && <>
              <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
                Chassis ID
              </CardTitle>
                <Autocomplete
                  groupBy={(option) => option.group}
                  disablePortal
                  open
                  // sx={{ height: 400 }}
                  // ListboxProps={{ style: { height: "600px" } }}
                  id="combo-box-demo"
                  options={chassisList}
                  renderInput={(params) => <TextField {...params} label="Select or Search Chassis ID" />}
                  getOptionLabel={(option: ChassisListAutocomplete) => option.label.split('_')[1]}
                  onChange={(event, value) => {setCurrentChassis(value)}}
                />

              </>}
            </CardBody>
          </Card>
        </div>
        <div className="col-12 col-md-8 col-xl-9">
          <Card
          >
            <CardBody>
              {currentChassis ?
                <>
                  <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
                    Master ID Password for {`${currentChassis.chassisType} ${currentChassis.chassisNo}`}
                  </CardTitle>
                  {loadingDetails && <p>Loading...</p>}
                  {!loadingDetails && errorDetails && <p>
                    There are no passwords available. Passwords are not created for failed processing.
                  </p>}
                  {
                    clipboard.copied && copyClickCoordinates ?
                      <div style={{
                        position: "fixed",
                        zIndex: 1000,
                        left: `${copyClickCoordinates.x}px`,
                        top: `${copyClickCoordinates.y}px`
                      }}>
                        <Alert severity="info">Copied to clipboard</Alert>
                      </div>
                    : null
                  }
                  {!loadingDetails && !errorDetails && dataDetails &&
                      dataDetails.map((d, i) => {

                          // @ts-ignore
                          return <Accordion key={d.id} expanded={expanded === `panel${d.id}`}
                                     onChange={handleChange(`panel${d.id}`)}>
                            {/*
                   // @ts-ignore */}
                            <AccordionSummary id={`panel${d.id}d-header`} style={{background: expanded === `panel${d.id}` ? 'rgba(25, 118, 210, 0.12)' : 'inherit'}}>
                              <Table borderless style={{marginBottom: 0, maxWidth: '660px' /* TODO responsiveness */}}>
                                <tbody>
                                {i === 0 ? <tr key={'header'}>{/* TODO align with tables of content */}
                                  <td style={{fontSize: '14px', width: '25%', fontWeight: '700'}}>
                                    Started
                                  </td>
                                  <td style={{fontSize: '14px', width: '25%', fontWeight: '700'}}>
                                    Completed
                                  </td>
                                  <td style={{fontSize: '14px', width: '25%', fontWeight: '700'}}>
                                    Total time
                                  </td>
                                  <td style={{fontSize: '14px', width: '25%', fontWeight: '700'}}>

                                  </td>
                                </tr> : null}
                                <tr key={'content'}>
                                  <td style={{fontSize: '14px', width: '25%'}}>
                                    {format(parseISO(d.started), "yyyy-MM-dd HH:mm")}
                                  </td>
                                  <td style={{fontSize: '14px', width: '25%'}}>
                                    {format(parseISO(d.completed), "yyyy-MM-dd HH:mm")}
                                  </td>
                                  <td style={{fontSize: '14px', width: '25%'}}>
                                    {d.totalTime.substring(0, 11)}
                                  </td>
                                  <td style={{fontSize: '14px', width: '25%'}}>
                                    {''}
                                  </td>
                                </tr>
                                </tbody>
                              </Table>
                            </AccordionSummary>
                            <AccordionDetails>
                              <div className={'table-master-id-password'}>
                                <Table key={d.id} borderless style={{
                                  marginBottom: 0,
                                  marginLeft: '24px',
                                  whiteSpace: 'nowrap',
                                  verticalAlign: 'middle',
                                  display: 'contents'
                                }}
                                >
                                  <tbody>
                                  <tr key={'header'}
                                    style={{}}>{/* TODO align with tables of content */}

                                    <td style={{fontSize: '14px', padding: '4px 8px'}}>
                                      <tr style={{fontWeight: '700'}}>{/* TODO align with tables of content */}
                                        <th style={{fontSize: '14px', padding: '4px 8px'}}>
                                          Name
                                        </th>
                                        <th style={{fontSize: '14px', padding: '4px 8px'}}>
                                          Password
                                        </th>
                                        <th style={{fontSize: '14px', padding: '4px 16px 4px 8px'}}>

                                        </th>
                                        <th style={{fontSize: '14px', padding: '4px 8px'}}>
                                          Class
                                        </th>
                                      </tr>
                                      {d.masterIdProps.map((u: MasterIdProps, index: number) =>
                                        <tr key={index}
                                          style={{backgroundColor: index % 2 ? 'transparent' : '#F4F4F4'}}>{/* TODO align with tables of content */}
                                          <td style={{fontSize: '14px', padding: '4px 8px'}}>
                                            {u.name}
                                          </td>
                                          <td style={{fontSize: '14px', padding: '4px 8px'}}>
                                            {u.password}
                                          </td>
                                          <td style={{fontSize: '14px', padding: '4px 16px 4px 8px'}}>
                                            <Button onClick={(e) => {
                                              handleCopy(u.password); setCopyClickCoordinates({x: e.clientX, y: e.clientY});
                                            }}><FontAwesomeIcon icon={faCopy} className={'copy-password-icon'}/> Copy password</Button>
                                          </td>
                                          <td style={{fontSize: '14px', padding: '4px 8px'}}>
                                            {u.class}
                                          </td>
                                        </tr>
                                      )}

                                    </td>
                                  </tr>
                                  </tbody>
                                </Table>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                      })
                  }
                </>
              : <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
                  Please select a chassis.
                </CardTitle>
              }
            </CardBody>
          </Card>
        </div>
      </div>
    </Page>
  );
}