import React, {useContext} from 'react';
import {Header} from "./Header";
import {Navigation} from "./Navigation";
import {Alert, Typography} from "@mui/material";
import {Input} from "reactstrap";
import {UserInfoContext} from "./App";
import useAxios, {UseAxiosResult} from "axios-hooks";

interface PageProps {
  children?: React.ReactNode;
  title?: string;
  subtitle?: string;
  refresh?: boolean;
  className?: string;
}

const REFRESH_OPTIONS = [
  {value: 1, label: 'Never'},
  {value: 60, label: '1 min'},
  {value: 180, label: '3 min'},
  {value: 300, label: '5 min'},
  {value: 600, label: '10 min'},
  {value: 900, label: '15 min'},
]

function Page(props: PageProps) {
  const {children, title, subtitle, refresh, className} = props;
  const { userData, setUserData } = useContext(UserInfoContext);

  const [{ error }, executePatch]: UseAxiosResult<any, any, any> = useAxios( {
    url: '/user/refreshtime',
    method: 'PATCH',
    headers: {
      'Content-Type': 'text/plain'
    }

  }, {manual: true});

  const handleChange = (e: any) => {
    executePatch({
      url: `/user/refreshtime?time=${e.target.value}`
    });
    setUserData({
      ...userData,
      refreshTime: Number(e.target.value)
    });
  }

  return (
    <>
      <Header borderBottom={(theme) => `1px solid ${theme.palette.divider}`}/>
      <Navigation/>
      <div className={'container-fluid'} style={{ scrollbarGutter: 'stable', overflow: 'auto', maxHeight: 'calc(100vh - 125px)'}}>
        {title || subtitle || refresh ?
          <div className="row">
            <div style={{display: 'flex', alignItems: "baseline"}}>
              {title ?
                <Typography margin={'40px 0'} fontSize={'24px'} fontWeight={'700'} variant="h1" color="inherit" noWrap sx={{flexGrow: 1}}>
                  {title}
                </Typography>
                : null
              }
              {refresh ?
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <div style={{display: 'flex', fontSize: '12px', fontWeight: '700', alignItems: "baseline"}}>
                    <span style={{marginRight: '10px'}}>Automatically refresh page after:</span>
                    <div>
                      <Input
                        id="exampleSelectMulti"
                        name="selectMulti"
                        type="select"
                        style={{width: "auto", fontSize: "12px"}}
                        onChange={handleChange}
                        value={userData?.refreshTime}
                      >{REFRESH_OPTIONS.map(option => <option key={option.label} label={option.label} value={option.value}>{option.label}</option>)}
                      </Input>
                    </div>
                  </div>
                  {error && <div><Alert severity="error">Failed to save preferred refresh time.</Alert></div>}
                </div>
                : null
              }
            </div>
            {subtitle ?
              <Typography margin={'0 0 40px 0'} fontSize={'18px'} fontWeight={'500'} variant="h2" color="inherit" noWrap
                          sx={{flexGrow: 1}}>
                {subtitle}
              </Typography>
              : null
            }
          </div>
          : null
        }
        <div className={className ? `${className}`: ``}>
          {children}
        </div>
      </div>
    </>
  );
}

export default Page;
