 import React, {useEffect, useState} from "react";
import './IncomingOrders.css';
import {Card, CardBody, CardTitle, Input, Table} from "reactstrap";
import useAxios, {UseAxiosResult} from "axios-hooks";
import Page from "./Page";

import {DataGrid, GridCellParams, GridToolbar} from '@mui/x-data-grid';
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {FactoryDelete} from "./FactoryDelete";
import {Checkbox} from "@mui/material";




  export function Roles() {

    const loading = false;
    const error = false;

  return(
    <Page title={'Roles'}>
      <div className="row">
        <div className="col-12">
          <p>under maintenance</p>
          {/*<Card>*/}
          {/*  <CardBody>*/}
          {/*    {loading && <p>Loading...</p>}*/}
          {/*    {error && <p>Error!!!</p>}*/}
          {/*    {true && <>*/}
          {/*    <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>*/}
          {/*      Assign permissions to roles*/}
          {/*    </CardTitle>*/}

          {/*    <div style={{display: 'inline-flex', flexDirection: 'column'}}>*/}
          {/*      <Table borderless style={{marginBottom: 0, display: 'block', fontSize: '14px'}}>*/}
          {/*        <tbody style={{display: 'table', width: '100%', tableLayout: 'fixed', maxWidth: '480px'}}>*/}
          {/*        <tr>*/}
          {/*          <th style={{paddingLeft: '4px'}}>Permission</th>*/}
          {/*          <th style={{textAlign: 'center'}}>User</th>*/}
          {/*          <th style={{textAlign: 'center'}}>Key User</th>*/}
          {/*          <th style={{textAlign: 'center'}}>Editor</th>*/}
          {/*          <th style={{textAlign: 'center'}}>Supervisor</th>*/}
          {/*        </tr>*/}
          {/*        {[*/}
          {/*          'create news',*/}
          {/*          'read MasterID Password',*/}
          {/*          'read Factories',*/}
          {/*          'edit Factories',*/}
          {/*          'read Body Order History',*/}
          {/*          'read Files Download',*/}
          {/*        ].map(*/}
          {/*          (item, index) =>*/}
          {/*            <tr key={item} style={{ backgroundColor: index % 2 === 1 ? 'white' : 'rgba(0, 0, 0, 0.03)'}}>*/}
          {/*              <td style={{verticalAlign: 'middle'}}>*/}
          {/*                {item}*/}
          {/*              </td>*/}
          {/*              <td style={{textAlign: 'center', verticalAlign: 'middle'}}>*/}
          {/*                <Checkbox />*/}
          {/*              </td>*/}
          {/*              <td style={{textAlign: 'center', verticalAlign: 'middle'}}>*/}
          {/*                <Checkbox />*/}
          {/*              </td>*/}
          {/*              <td style={{textAlign: 'center', verticalAlign: 'middle'}}>*/}
          {/*                <Checkbox />*/}
          {/*              </td>*/}
          {/*              <td style={{textAlign: 'center', verticalAlign: 'middle'}}>*/}
          {/*                <Checkbox />*/}
          {/*              </td>*/}
          {/*            </tr>*/}
          {/*        )}*/}

          {/*        </tbody>*/}
          {/*      </Table>*/}
          {/*      <div style={{display: 'flex', justifyContent: 'right', padding: '16px 0 6px 0'}}>*/}
          {/*        <Button variant="contained">*/}
          {/*          Update roles*/}
          {/*        </Button>*/}
          {/*      </div>*/}

          {/*    </div>*/}

          {/*    </>}*/}
          {/*  </CardBody>*/}
          {/*</Card>*/}
        </div>
      </div>
    </Page>
  );
}