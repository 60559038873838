import React from 'react';
import { hydrate, render } from "react-dom";
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import { msalConfig } from "./authConfig";
import {PublicClientApplication} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import App from "./App";

const msalInstance = new PublicClientApplication(msalConfig);
const AppWithAuth = <MsalProvider instance={msalInstance}><App /></MsalProvider>;

const rootElement = document.getElementById("root");
if (rootElement?.hasChildNodes()) {
  hydrate(AppWithAuth, rootElement);
} else {
  render(AppWithAuth, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
