import React from 'react';
// import {Link} from "react-router-dom";
import {Typography} from '@mui/material';
import {Navigation} from "./Navigation";
import {Header} from "./Header";

function Tools() {
  return (
    <>
      <Header borderBottom={(theme) => `1px solid ${theme.palette.divider}`}/>
      <Navigation/>
      <Typography variant="h4" color="inherit" noWrap sx={{flexGrow: 1}}>
        BSP Files Download
      </Typography>


      tools


      {/*<div className="App">*/}
      {/*  <header className="App-header">*/}
      {/*    <img src={logo} className="App-logo" alt="logo" />*/}
      {/*    <p>*/}
      {/*      Edit <code>src/App.tsx</code> and save to reload.*/}
      {/*    </p>*/}
      {/*    <h2>it's the home page</h2>*/}
      {/*    <Button variant="contained">Hello World</Button>*/}
      {/*    /!*<Link to="/page">Page</Link>*!/*/}
      {/*  </header>*/}
      {/*</div>*/}
    </>
  );
}

export default Tools;
