import {Card, CardBody, Form, FormGroup, Input, Table} from "reactstrap";
import React, {useContext, useEffect, useState} from "react";
import useAxios, {UseAxiosResult} from "axios-hooks";
import {Link} from "react-router-dom";
import {styles} from "./Home";
import {UserInfoContext} from "./App";
import {useInterval} from "usehooks-ts";
import Loader from "./Loader";
import {Alert} from "@mui/material";

type ChassisType = string;
type ChassisNo = number;

type Country = string;

interface Time {
  "hours": number;
  "minutes": number;
  "seconds": number;
}

interface Stats {
  "processedOK": number;
  "processedNOK": number;
  "orderCount": number;
  "avarageTime": Time,
  "longestTime": {
    "time": Time;
    "chassisType": ChassisType;
    "chassisNo": ChassisNo;
  },
  "shortestTime": {
    "time": Time;
    "chassisType": ChassisType;
    "chassisNo": ChassisNo;
  }
}

export function History() {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedTime, setSelectedTime] = useState('Today');
  const [{ data: countries, loading: loadingCountries, error: errorCountries }]: UseAxiosResult<Country[] | undefined, boolean, any> = useAxios(`/country`);
  const [{ data, loading, error }, fetchStats] : UseAxiosResult<Stats | undefined, boolean, any> = useAxios(`/stats?${selectedCountry ? `country=${selectedCountry}` : ''}${selectedCountry&&selectedTime?'&':''}time=${selectedTime}`, {useCache: false, manual: true});
  const {userData} = useContext(UserInfoContext);

  const [refreshTime, setRefreshTime] = useState<number | null>(userData?.refreshTime ?? null)

  useEffect(() => {
    fetchStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    fetchStats();
  }, [selectedCountry, selectedTime])

  useEffect(() => {
    const interval = userData?.refreshTime;
    if (interval && interval !== 1) {
      setRefreshTime(interval)
    } else {
      setRefreshTime(null)
    }
  }, [userData?.refreshTime])

  useInterval(
    () => {
      fetchStats();
    },
    refreshTime && typeof refreshTime === 'number' ? refreshTime * 1000 : null
  )

  return <Card className={'history'}>
    <CardBody>
      {
        (loadingCountries || loading) ? <p>Loading <Loader size={12}/></p>
        :
          (errorCountries || error) ? <Alert severity="error">Error loading statistics.</Alert>
            :
          !loadingCountries && !loading && <Table borderless style={{marginBottom: 0, display: "block"}}>
            <thead>
            <tr>
              <th style={{paddingLeft: '4px', paddingRight: '4px'}}>
                <Form><FormGroup className={"no-margin-bottom"}>
                  {countries ?
                  <Input
                    id="country"
                    name="country"
                    type="select"
                    style={{width: "auto", fontSize: "14px"}}
                    onChange={((e: any) => {setSelectedCountry(e.target.value)})}
                    value={selectedCountry}
                  >
                    {[
                      <option key={'all'} value={''}>All</option>,
                      ...countries.map(c => {
                      return <option key={c} value={c}>{c}</option>
                    })
                    ]}
                  </Input> : null}
                </FormGroup></Form>

              </th>
              <th colSpan={2} style={{paddingLeft: '4px', paddingRight: '4px'}}>
                <Form><FormGroup className={"no-margin-bottom"}>
                  <Input
                    id="exampleSelectMulti"
                    name="selectMulti"
                    type="select"
                    style={{width: "auto", fontSize: "14px"}}
                    onChange={((e: any) => {setSelectedTime(e.target.value)})}
                    value={selectedTime}
                  >
                    <option value="Today">Today</option>
                    <option value="ThisWeek">This Week</option>
                    <option value="ThisMonth">This Month</option>
                    <option value="ThisYear">This Year</option>
                    <option value="Infinity">All data</option>
                  </Input>
                </FormGroup></Form>
              </th>
            </tr>
            </thead>
            { data ? <tbody>
            <tr>
              <td style={{fontSize: "14px", fontWeight: "700"}}>
                Order processed:
              </td>
              <td style={{fontSize: "14px"}} colSpan={2}>
                {data.orderCount}
              </td>
            </tr>
            <tr>
              <td style={{fontSize: "14px", fontWeight: "700"}}>
                Status OK:
              </td>
              <td style={{fontSize: "14px"}} colSpan={2}>
                {data.processedOK}
              </td>
            </tr>
            <tr>
              <td style={{fontSize: "14px", fontWeight: "700"}}>
                Status NOK:
              </td>
              <td style={{fontSize: "14px"}} colSpan={2}>
                {data.processedNOK}
              </td>
            </tr>
            <tr>
              <td style={{fontSize: "14px", fontWeight: "700"}}>
                Average processing time:
              </td>
              <td style={{fontSize: "14px"}} colSpan={2}>
                {data.avarageTime.hours}h {data.avarageTime.minutes}m {data.avarageTime.seconds}s
              </td>
            </tr>
            <tr>
              <td style={{fontSize: "14px", fontWeight: "700"}}>
                Longest processing time:
              </td>
              <td style={{fontSize: "14px"}}>
                {data.longestTime.time.hours}h {data.longestTime.time.minutes}m {data.longestTime.time.seconds}s
              </td>
              <td style={{fontSize: "14px"}}>
                {data.longestTime.chassisNo && data.longestTime.chassisType ?
                <Link style={styles.link} to={`/body-order-history?chassisType=${data.longestTime.chassisType}&chassisNo=${data.longestTime.chassisNo}`}>{data.longestTime.chassisType} {data.longestTime.chassisNo}</Link>
                  : null
                }
              </td>
            </tr>
            <tr>
              <td style={{fontSize: "14px", fontWeight: "700"}}>
                Shortest processing time:
              </td>
              <td style={{fontSize: "14px"}}>
                {data.shortestTime.time.hours}h {data.shortestTime.time.minutes}m {data.shortestTime.time.seconds}s
              </td>
              <td style={{fontSize: "14px"}}>
                {data.shortestTime.chassisNo && data.shortestTime.chassisType ?
                  <Link style={styles.link} to={`/body-order-history?chassisType=${data.shortestTime.chassisType}&chassisNo=${data.shortestTime.chassisNo}`}>{data.shortestTime.chassisType} {data.shortestTime.chassisNo}</Link>
                  : null
                }
              </td>
            </tr>
            </tbody> : null}
          </Table>
      }
    </CardBody>
  </Card>;
}