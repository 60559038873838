import React, {useEffect, useState} from "react";
import './IncomingOrders.css';
import {Card, CardBody, CardTitle, Input} from "reactstrap";
import useAxios, {UseAxiosResult} from "axios-hooks";
import Page from "./Page";
import {Autocomplete, IconButton, TextField, Typography} from "@mui/material";

import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import {format, isAfter, isBefore, isSameDay, parseISO} from "date-fns";
import {DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-regular-svg-icons";
import Button from "@mui/material/Button";

interface FactoryDTO {
  busOrderCode: string;
  description: string;
  factoryId: string;
  isStorable: boolean;
  oasCode: string;
  plantCode: string;
}



  export function FactoryEdit() {


  // todo fetch specific factory data


  const rowHeight = 51;

  return(
    <Page title={'Factory editing'}>
      <div className="row" style={{marginBottom: '24px'}}>
        <div className="col-4">
          <Card className={'chassis-selector'}>
            <CardBody>
              {/*{loading && <p>Loading...</p>}*/}
              {/*{error && <p>Error!!!</p>}*/}
              {true /*data*/ && <>
              <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
                Factory settings
              </CardTitle>

                <div style={{display: 'flex', fontSize: '12px', alignItems: "baseline"}}>
                  <span style={{marginRight: '10px', fontWeight: 'bold'}}>Factory ID:</span>
                  <div>
                    <Input
                      type="text"
                      style={{width: "auto", fontSize: "12px"}}
                    ></Input>
                  </div>
                </div>
                <div style={{display: 'flex', fontSize: '12px', alignItems: "baseline"}}>
                  <span style={{marginRight: '10px'}}>HFI Parameter:</span>
                  <div>
                    <Input
                      type="text"
                      style={{width: "auto", fontSize: "12px"}}
                    ></Input>
                  </div>
                </div>
                <div style={{display: 'flex', fontSize: '12px', alignItems: "baseline"}}>
                  <span style={{marginRight: '10px'}}>OAS Code:</span>
                  <div>
                    <Input
                      type="text"
                      style={{width: "auto", fontSize: "12px"}}
                    ></Input>
                  </div>
                </div>
                <div style={{display: 'flex', fontSize: '12px', alignItems: "baseline"}}>
                  <span style={{marginRight: '10px'}}>Description:</span>
                  <div>
                    <Input
                      type="text"
                      style={{width: "auto", fontSize: "12px"}}
                    ></Input>
                  </div>
                </div>
                <div style={{display: 'flex', fontSize: '12px', alignItems: "baseline"}}>
                  <span style={{marginRight: '10px'}}>FTA Parameter</span>
                  <div>
                    <Input
                      id="exampleSelectMulti"
                      name="selectMulti"
                      type="select"
                      style={{width: "auto", fontSize: "12px"}}
                    >
                      <option value="A">A</option>
                      <option value="C">C</option>
                      <option value="E">E</option>
                      <option value="H">H</option>
                    </Input>
                  </div>
                </div>
              </>}
            </CardBody>
          </Card>
        </div>
      </div>
    </Page>
  );
}