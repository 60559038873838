interface EnvInterface {
  dev: string;
  qa: string;
  prod: string;
}

export const Env: EnvInterface = {
  dev: "development",
  qa: "test",
  prod: "production",
}

export const getUniqueListBy = <T>(arr: T[], key: keyof T): T[] => {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}

export function formatXml(xml: string): string { // https://gist.github.com/sente/1083506?permalink_comment_id=3068708#gistcomment-3068708
  // Remove all the newlines and then remove all the spaces between tags
  xml = xml.replace(/(\r\n|\n|\r)/gm, " ").replace(/>\s+</g, '><');

  const PADDING = ' '.repeat(2); // set desired indent size here
  const reg = /(>)(<)(\/*)/g;
  let pad = 0;

  xml = xml.replace(reg, '$1\r\n$2$3');

  return xml.split('\r\n').map((node, index) => {
    let indent = 0;
    if (node.match(/.+<\/\w[^>]*>$/)) {
      indent = 0;
    } else if (node.match(/^<\/\w/) && pad > 0) {
      pad -= 1;
    } else if (node.match(/^<\w[^>]*[^/]>.*$/)) {
      indent = 1;
    } else {
      indent = 0;
    }

    pad += indent;

    return PADDING.repeat(pad - indent) + node;
  }).join('\r\n');
}

export function formatWarningsAndErrors(xml: string): string {
  const regex = /(warning|error):/gmi;
  return xml.replace(regex, (match, p1, p2, p3, offset, string) => `\n${match}`);
}