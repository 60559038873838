import React from "react";
import './BodyOrderHistory.css';
import {Card, CardBody, CardTitle} from "reactstrap";
import Page from "./Page";

interface NoPermissionsProps {
  pageName?: string;
  isFetchingResources?: boolean;
}

export function NoPermissions({pageName, isFetchingResources}: NoPermissionsProps) {

 return(
    <Page title={'No permissions'}>
      <div className="row">
        <div className="col-12">
          <Card style={{marginBottom: '24px'}}>
            <CardBody>
              {isFetchingResources ?
                <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
                  You don't have permissions to fetch any data. Please write an e-mail describing which pages would you like to access to <a href="mailto:support.industrialapps@volvo.com">support.industrialapps@volvo.com</a>
                </CardTitle>
                :
              <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
                You don't have permissions to {pageName} page. Please write an e-mail to <a href="mailto:support.industrialapps@volvo.com">support.industrialapps@volvo.com</a>
              </CardTitle>
              }
            </CardBody>
          </Card>
        </div>
      </div>
    </Page>
  );
}