import React, {useCallback, useContext, useEffect, useState} from "react";
import './BodyOrderHistory.css';
import {Card, CardBody, CardTitle, Table} from "reactstrap";
import useAxios, {UseAxiosResult} from "axios-hooks";
import Page from "./Page";
import {Autocomplete, Box, IconButton, Modal, TextField, Typography} from "@mui/material";
import {Accordion, AccordionDetails, AccordionSummary} from "./Accordion";
import {useSearchParams} from "react-router-dom";
import {format, parseISO} from "date-fns";
import {formatWarningsAndErrors as functionFormatWarningsAndErrors, formatXml, getUniqueListBy} from "./utils";
import Button from "@mui/material/Button";
import {modalStyle} from "./News";
import {useClipboard} from "use-clipboard-copy";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy} from "@fortawesome/free-regular-svg-icons";
import { UNSAFE_NavigationContext } from "react-router-dom";
import {useHash} from "./hooks";
import {EMPTY_CHASSIS_ID} from "./IncomingOrders";

export interface ChassisDTO {
  chassisType: string;
  chassisNo: number;
}

export interface ChassisListAutocomplete extends ChassisDTO {
  group: string;
  label: string;
}

interface OrderDetailsDTO {
  isFilesDownloaded: boolean;
  isComponentListChecked: boolean;
  isSent: boolean;
  isPostProcessorFilesCopied: boolean;
  cvspInData: string;
  postProcess: string;
  preparePostProcessor: string;
  components: string;
}

interface DetailsDTO {
  completed: string; // ISO 8601
  result: boolean;
  started: string; // ISO 8601
  totalTime: string;
  orderDetailsDTO: OrderDetailsDTO;
  id: number;
}

const useBackListener = (callback: any) => {
  const navigator = useContext(UNSAFE_NavigationContext).navigator;

  useEffect(() => {
    const listener = ({ location, action }: any) => {
      if (action === "POP") {
        callback({ location, action });
      }
    };

    const unlisten = (navigator as any).listen(listener);
    return unlisten;
  }, [callback, navigator]);
};

export function BodyOrderHistory() {

  let searchParamsObject = Object.fromEntries(new URLSearchParams(window.location.search).entries()) as unknown as ChassisDTO;
  let validSearchParamsObject: ChassisDTO = EMPTY_CHASSIS_ID;
  if (Object.keys(searchParamsObject).length === 2 && searchParamsObject['chassisType'] && searchParamsObject['chassisNo']) {
    validSearchParamsObject = searchParamsObject;
  }
  let initialValue = '';
  if (validSearchParamsObject.chassisNo && validSearchParamsObject.chassisNo !== 0) {
    initialValue = `${validSearchParamsObject.chassisType} ${validSearchParamsObject.chassisNo}`;
  }

  const [currentChassis, setCurrentChassis] = useState<ChassisDTO>(validSearchParamsObject);
  const [hash, setHash] = useHash();
  const [previewedFiles, setPreviewedFiles] = useState<string[]>([]);
  const [downloadedFiles, setDownloadedFiles] = useState<string[]>([]);
  const [chassisList, setChassisList] = useState<ChassisListAutocomplete[]>([]);
  const [modalContent, setModalContent] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPreWrapped, setIsPreWrapped] = useState(true);
  const [fullWidth, setFullWidth] = useState(false);
  const [formatWarningsAndErrors, setFormatWarningsAndErrors] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const newChassisType = searchParams.get('chassisType');
    const newChassisNo = searchParams.get('chassisNo');
    if (newChassisType && newChassisNo) {
      setCurrentChassis({
        chassisType: newChassisType,
        chassisNo: parseInt(newChassisNo, 10),
      })
    }
  }, [searchParams])

  useBackListener(({ location }: any) => {
    if (location.search.indexOf('chassisNo') === -1 || location.search.indexOf('chassisType') === -1) {
      setCurrentChassis(EMPTY_CHASSIS_ID);
    }
  });

  const [{
    data,
    loading,
    error
  }]: UseAxiosResult<ChassisDTO[] | undefined, boolean, any> = useAxios('/bodyorder/chassislist');
  const [{
    data: dataDetails,
    loading: loadingDetails,
    error: errorDetails
  }, fetchDetails]: UseAxiosResult<DetailsDTO[] | undefined, boolean, any> = useAxios(
    `/bodyorder/details?chassisType=${currentChassis?.chassisType.trim()}&chassisNo=${currentChassis?.chassisNo}`,{manual: true}
  );
  const [{
    data: dataOkchassislist,
    loading: loadingOkchassislist,
    error: errorOkchassislist
  }]: UseAxiosResult<ChassisDTO[] | undefined, boolean, any> = useAxios('/bodyorder/okchassislist');
  const [{
    data: dataNokchassislist,
    loading: loadingNokchassislist,
    error: errorNokchassislist
  }]: UseAxiosResult<ChassisDTO[] | undefined, boolean, any> = useAxios('/bodyorder/nokchassislist');
  const [{
    data: dataLastchassislist,
    loading: loadingLastchassislist,
    error: errorLastchassislist
  }]: UseAxiosResult<ChassisDTO[] | undefined, boolean, any> = useAxios('/bodyorder/lastchassislist');

  useEffect(() => {
    if (dataOkchassislist && dataNokchassislist && dataLastchassislist && data) {
      setChassisList([
        ...getUniqueListBy(dataOkchassislist.map(c => ({
          ...c,
          group: 'Last OK Processed',
          label: `ok_${c.chassisType} ${c.chassisNo}`
        })), 'label'),
        ...getUniqueListBy(dataNokchassislist.map(c => ({
          ...c,
          group: 'Last NOK Processed',
          label: `nok_${c.chassisType} ${c.chassisNo}`
        })), 'label'),
        ...getUniqueListBy(dataLastchassislist.map(c => ({
          ...c,
          group: 'Recently Processed',
          label: `recent_${c.chassisType} ${c.chassisNo}`
        })), 'label'),
        ...getUniqueListBy(data.map(c => ({
          ...c,
          group: 'All Processed',
          label: `all_${c.chassisType} ${c.chassisNo}`
        })), 'label'),
        {
          ...EMPTY_CHASSIS_ID,
          group: 'All Processed',
          label: `all_${EMPTY_CHASSIS_ID.chassisType} ${EMPTY_CHASSIS_ID.chassisNo}`
        }

      ]);
    }
  }, [dataOkchassislist, dataNokchassislist, dataLastchassislist, data])

  useEffect( () => {
    if (currentChassis && currentChassis.chassisNo !== 0) {
      fetchDetails();
    }
  }, [currentChassis, fetchDetails]);

  const handleChange = (panel: string) => (event: any, newExpanded: any) => {
    if (newExpanded) {
      setHash(panel)
    }
    else {
      setHash('');
    }
  };

  const closeModal = () => {
    setModalContent('');
    setIsModalOpen(false);
  }

  const clipboard = useClipboard();

  const handleCopy = useCallback(
    () => {
      clipboard.copy(modalContent);
    },
    [clipboard, modalContent]
  );

  const handlePreWrap = useCallback(
    () => {
      setIsPreWrapped(!isPreWrapped);
    },
    [isPreWrapped]
  );

  const handleFormatWarningsAndErrors = useCallback(
    () => {
      setFormatWarningsAndErrors(!formatWarningsAndErrors);
    },
    [formatWarningsAndErrors]
  );

  const handleFullWidth = useCallback(
    () => {
      setFullWidth(!fullWidth);
    },
    [fullWidth]
  );

  useEffect( () => {
    const previewFromStorage = localStorage.getItem('previewedFiles');
    if (previewFromStorage) {
      setPreviewedFiles(JSON.parse(previewFromStorage));
    }
  }, [])

  const handlePreviewedFile = useCallback(
    (filename) => {
      if (!previewedFiles.includes(filename)) {
        const updatedFiles = [...previewedFiles, filename];
        setPreviewedFiles(updatedFiles);
        localStorage.setItem('previewedFiles', JSON.stringify(updatedFiles));
      }
    },
    [previewedFiles]
  );

  useEffect( () => {
    const downloadFromStorage = localStorage.getItem('downloadedFiles');
    if (downloadFromStorage) {
      setDownloadedFiles(JSON.parse(downloadFromStorage));
    }
  }, [])

  const handleDownloadedFile = useCallback(
    (filename) => {
      if (!downloadedFiles.includes(filename)) {
        const updatedFiles = [...downloadedFiles, filename];
        setDownloadedFiles(updatedFiles);
        localStorage.setItem('downloadedFiles', JSON.stringify(updatedFiles));
      }
    },
    [downloadedFiles]
  );

  return (
    <Page title={'Body Order History'}>
      <div className="row">
        <div className="col-12 col-md-4 col-xl-3">
          <Card className={'chassis-selector'} style={{marginBottom: '24px'}}>
            <CardBody>
              {(loading || loadingOkchassislist || loadingNokchassislist || loadingLastchassislist) && <p>Loading...</p>}
              {(error || errorLastchassislist || errorNokchassislist || errorOkchassislist) && <p>Error!!!</p>}
              {(dataNokchassislist && dataLastchassislist && dataOkchassislist && data) && <>
                <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
                  Chassis ID
                </CardTitle>
                <Autocomplete
                  value={
                  chassisList.find(
                      chassis => chassis.chassisNo === currentChassis?.chassisNo && chassis.chassisType === currentChassis.chassisType
                    )
                    ?? {
                    ...EMPTY_CHASSIS_ID,
                      group: 'All Processed',
                      label: `all_${EMPTY_CHASSIS_ID.chassisType} ${EMPTY_CHASSIS_ID.chassisNo}`
                    }
                  }
                  inputValue={value}
                  onInputChange={
                    (event: any, newValue: string | null) => {
                      if (newValue === null) {
                        setValue('')
                      }
                    else {
                        setValue(newValue.toUpperCase())
                      }
                    }
                  }
                  groupBy={(option) => option.group}
                  disablePortal
                  open
                  // sx={{ height: 400 }}
                  // ListboxProps={{ style: { height: "600px" } }}
                  id="combo-box-demo"
                  options={chassisList}
                  renderInput={(params) =>
                    <TextField {...params} label="Select or Search Chassis ID"/>
                  }
                  getOptionLabel={(option: ChassisListAutocomplete) => option.label === 'all_ 0' ? '' : option.label.split('_')[1]}
                  onChange={(event, value) => {
                    if (value) {
                      setSearchParams(Object.entries(value as ChassisDTO).filter(entry => ['chassisType', 'chassisNo'].includes(entry[0])));
                    } else {
                      setCurrentChassis(EMPTY_CHASSIS_ID)
                      searchParams.delete('chassisType');
                      searchParams.delete('chassisNo');
                      setSearchParams(searchParams);
                    }
                  }}
                />

              </>}
            </CardBody>
          </Card>
        </div>
        <div className="col-12 col-md-8 col-xl-9">
          <Card
          >
            <CardBody>
              {(loadingDetails || loadingOkchassislist || loadingNokchassislist || loadingLastchassislist) && <p>Loading...</p>}
              {errorDetails && <p>Error!!!</p>}
              {
                (!currentChassis?.chassisType || !currentChassis.chassisNo) && !loadingDetails && !loadingOkchassislist && !loadingNokchassislist && !loadingLastchassislist ?
                  <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
                    Please select a chassis.
                  </CardTitle>
                  : null
              }
              {
                currentChassis?.chassisType && currentChassis.chassisNo && !(loadingDetails || loadingOkchassislist || loadingNokchassislist || loadingLastchassislist) && dataDetails ?
                <>
                  <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
                    Body Order History for chassis {`${currentChassis.chassisType} ${currentChassis.chassisNo}`}
                  </CardTitle>
                  {dataDetails && dataDetails.map((details: DetailsDTO, index: number) =>
                    // @ts-ignore
                    <Accordion key={details.id} expanded={hash.substring(1) === `${details.id}`}
                               onChange={handleChange(`${details.id}`)}>
                      {/*
                   // @ts-ignore */}
                      <AccordionSummary id={`panel${details.id}d-header`} style={{background: hash.substring(1) === `${details.id}` ? 'rgba(25, 118, 210, 0.12)' : 'inherit'}}>
                        <Table borderless style={{marginBottom: 0, maxWidth: '660px' /* TODO responsiveness */}}>
                          <tbody>
                          {index === 0 ? <tr key={'header'}>{/* TODO align with tables of content */}
                            <td style={{fontSize: '14px', width: '25%', fontWeight: '700'}}>
                              Completed
                            </td>
                            <td style={{fontSize: '14px', width: '25%', fontWeight: '700'}}>
                              Started
                            </td>
                            <td style={{fontSize: '14px', width: '25%', fontWeight: '700'}}>
                              Total time
                            </td>
                            <td style={{fontSize: '14px', width: '25%', fontWeight: '700'}}>
                              Result
                            </td>
                          </tr> : null}
                          <tr key={'content'}>
                            <td style={{fontSize: '14px', width: '25%'}}>
                              {format(parseISO(details.completed), "yyyy-MM-dd HH:mm")}
                            </td>
                            <td style={{fontSize: '14px', width: '25%'}}>
                              {format(parseISO(details.started), "yyyy-MM-dd HH:mm")}
                            </td>
                            <td style={{fontSize: '14px', width: '25%'}}>
                              {details.totalTime.substring(0, 11)}
                            </td>
                            <td style={{fontSize: '14px', width: '25%'}}>
                              {details.result ? 'OK' : 'NOK'}
                            </td>
                          </tr>
                          </tbody>
                        </Table>
                      </AccordionSummary>
                      <AccordionDetails>
                        {details.orderDetailsDTO ?
                          <div className={'order-details-container'}>
                            <Table borderless style={{marginBottom: 0, display: 'block', maxWidth: '660px'}}>
                              <tbody style={{display: 'table', width: '100%'}}>
                              {[
                                {
                                  label: 'Component List Checked',
                                  value: String(details.orderDetailsDTO.isComponentListChecked),
                                },
                                {
                                  label: 'Files Downloaded',
                                  value: String(details.orderDetailsDTO.isFilesDownloaded),
                                },
                                {
                                  label: 'Post Processor Files Copied',
                                  value: String(details.orderDetailsDTO.isPostProcessorFilesCopied),
                                },
                                {
                                  label: 'Sent',
                                  value: String(details.orderDetailsDTO.isSent),
                                },
                              ].map(
                                (item, index) =>
                                  <tr key={item.label} style={{ backgroundColor: index % 2 === 0 ? 'white' : 'rgba(0, 0, 0, 0.03)'}}>
                                    <td style={{width: '75%'}}>
                                      {item.label}
                                    </td>
                                    <td style={{paddingLeft: '12px'}}>
                                      {!!item.value ? 'OK' : 'NOK'}
                                    </td>
                                  </tr>
                              )}

                              </tbody>
                            </Table>
                            <Table borderless style={{marginBottom: 0, marginTop: '16px', display: 'block'}}>
                              <tbody style={{display: 'table', width: '100%'}}>
                              {[
                                {
                                  label: 'CVSP in Data',
                                  filename: 'cvspInData',
                                  content: details.orderDetailsDTO.cvspInData
                                },
                                {
                                  label: 'Prepare Post Processor',
                                  filename: 'preparePostProcessor',
                                  content: details.orderDetailsDTO.preparePostProcessor
                                },
                                {
                                  label: 'Post Process',
                                  filename: 'postProcess',
                                  content: details.orderDetailsDTO.postProcess
                                },
                                {
                                  label: 'Components',
                                  filename: 'components',
                                  content: details.orderDetailsDTO.components
                                },
                              ].map(
                                (item, index) => <tr key={item.label} style={{backgroundColor: index % 2 === 0 ? 'white' : 'rgba(0, 0, 0, 0.03)'}}>
                                  <td style={{width: '75%'}}>
                                    {item.label}
                                  </td>
                                  <td className={'preview-cell'}>
                                    <Button className={`no-underline ${downloadedFiles.includes(`${details.id}__${item.filename}`) ? 'visited' : ''}`}
                                       href={URL.createObjectURL(new Blob(
                                         [item.content],
                                         {type: 'text/xml'}))}
                                       download={`${item.filename} ${currentChassis?.chassisType} ${currentChassis?.chassisNo}.xml`}
                                       onClick={() => {
                                         handleDownloadedFile(`${details.id}__${item.filename}`);
                                       }}
                                    >Download</Button>
                                  </td>
                                  <td className={'preview-cell'}>
                                    <Button className={`no-underline ${previewedFiles.includes(`${details.id}__${item.filename}`) ? 'visited' : ''}`}
                                      onClick={() => {
                                        setModalContent(formatXml(item.content));
                                        setIsModalOpen(true);
                                        handlePreviewedFile(`${details.id}__${item.filename}`);
                                      }}
                                    >
                                      Preview
                                    </Button>
                                  </td>
                                </tr>
                              )}

                              </tbody>
                            </Table>
                          </div>
                          : <Typography>
                            No processing details available.
                          </Typography>}
                      </AccordionDetails>
                    </Accordion>
                  )
                  }
                  <Modal
                    open={isModalOpen}
                    onClose={closeModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={{...modalStyle, ...(fullWidth ? {width: '99vw'} : {width: 'auto', maxWidth: '800px'})}}>
                      <Box display="flex" alignItems="center"
                           style={{position: 'sticky', top: '-32px', background: 'white'}}>
                        <Box flexGrow={1}>
                          <Button onClick={handleCopy}><FontAwesomeIcon icon={faCopy}/>&nbsp;Copy to clipboard</Button>|
                          <Button onClick={handleFullWidth}>{fullWidth ? 'Auto width' : 'Full width'}</Button>|
                          <Button onClick={handlePreWrap}>{isPreWrapped ? 'Don\'t wrap text' : 'Wrap text'}</Button>|
                          <Button onClick={handleFormatWarningsAndErrors}>{formatWarningsAndErrors ? 'Don\'t format warnings and errors' : 'Format warnings and errors'}</Button>
                        </Box>
                        <Box>
                          <IconButton className={'no-underline'} onClick={closeModal}>
                            &#215;
                          </IconButton>
                        </Box>
                      </Box>
                      <pre style={{...(isPreWrapped? {whiteSpace: 'pre-wrap'}: {whiteSpace: 'pre'})}}>
                        {formatWarningsAndErrors ? functionFormatWarningsAndErrors(modalContent) : modalContent}
                      </pre>
                    </Box>
                  </Modal>
                </>
                : null
              }
            </CardBody>
          </Card>
        </div>
      </div>
    </Page>
  );
}