import React, {useEffect, useState} from "react";
import './IncomingOrders.css';
import {Card, CardBody, CardTitle, Input} from "reactstrap";
import useAxios, {UseAxiosResult} from "axios-hooks";
import Page from "./Page";

import {DataGrid, GridCellParams, GridToolbar} from '@mui/x-data-grid';
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {FactoryDelete} from "./FactoryDelete";

export interface FactoryDTO {
  busOrderCode: string;
  description: string;
  id: number;
  isStorable: boolean;
  oasCode: string;
  plantCode: string;
  factoryName: string;
}

function RatingEditInputCell(props: any) {
  const { id, value, api, field } = props;

  return <button>t</button>
  };

  function renderRatingEditInputCell(params: any) {
    // @ts-ignore
    return <RatingEditInputCell {...params} />;
  }

  export function Factories() {
  const [expanded, setExpanded] = useState('panel1');
  const [pageSize, setPageSize] = useState(5);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [factories, setFactories] = useState<FactoryDTO[]>([]);
  const [factoryToBeDeleted, setFactoryToBeDeleted] = useState<FactoryDTO | undefined>(undefined);

  const [{ data: dataDefaultFactory, loading: loadingDefaultFactory, error: errorDefaultFactory }]: UseAxiosResult<FactoryDTO[] | undefined, boolean, any> = useAxios('/factory/getDefaultFactory'); // todo use it when it'll possible to change (save - user)
  const [{ data, loading, error }]: UseAxiosResult<FactoryDTO[] | undefined, boolean, any> = useAxios('/factory/getFactories');

    useEffect( () => {
      if (data) {
        setFactories(data);
      }
    }, [data])

  const handleChange = (panel: any) => (event: any, newExpanded: any) => {
    setExpanded(newExpanded ? panel : false);
  };

  const columns = [
    {
      field: 'actions',
      headerName: '',
      width: 260,
      renderCell: (params: GridCellParams<any, FactoryDTO, any>) =>
        <>
          <Button className={'no-underline'}><Link
            color="text.primary"
            to="/factory-editing"
          >Edit
          </Link></Button>
          <Button className={'no-underline'}>Copy</Button>
          <Button className={'no-underline'} onClick={() => {setFactoryToBeDeleted(params.row)}}>Delete</Button>
        </>
    },
    {
      field: 'id',
      headerName: 'Factory id',
      width: 150,
    },
    {
      field: 'oasCode',
      headerName: 'Oas Code',
      width: 150,

    },
    {
      field: 'busOrderCode',
      headerName: 'BO Code',
      type: 'string',
      width: 150,
    },
    {
      field: 'plantCode',
      headerName: 'Plant Code',
      width: 150,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 360,
    },
    {
      field: 'isStorable',
      headerName: 'Storage',
      width: 150,
    }
  ];

  const rowHeight = 51;

  return(
    <Page title={'Factories'}>
      {/*<div className="row" style={{marginBottom: '24px'}}>*/}
      {/*  <div className="col-4">*/}
      {/*    <Card className={'chassis-selector'}>*/}
      {/*      <CardBody>*/}
      {/*        {loading && <p>Loading...</p>}*/}
      {/*        {error && <p>Error!!!</p>}*/}
      {/*        {data && <>*/}
      {/*        <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>*/}
      {/*          Settings*/}
      {/*        </CardTitle>*/}
      {/*          <p>under maintenance</p>*/}
      {/*          <div style={{display: 'flex', fontSize: '12px', alignItems: "baseline"}}>*/}
      {/*            <span style={{marginRight: '10px'}}>Default&nbsp;factory:</span>*/}
      {/*            <div>*/}
      {/*              <Input*/}
      {/*                id="exampleSelectMulti"*/}
      {/*                name="selectMulti"*/}
      {/*                type="select"*/}
      {/*                style={{width: "auto", fontSize: "12px"}}*/}
      {/*              >*/}
      {/*                <option value="CDAPROD1 (Carrus Delta, Finland)">CDAPROD1 (Carrus Delta, Finland)</option>*/}
      {/*                <option value="CDAPROD2 (Carrus Delta, Finland)">CDAPROD2 (Carrus Delta, Finland)</option>*/}
      {/*                <option value="CDATEST (Carrus Delta, Finland Test)">CDATEST (Carrus Delta, Finland Test)</option>*/}
      {/*                <option value="WROPROD3 (Wrocław, Poland (old Säffle, Sweden))">WROPROD3 (Wrocław, Poland (old*/}
      {/*                  Säffle, Sweden))*/}
      {/*                </option>*/}
      {/*                <option value="WROPROD4 (Wrocław, Poland (old Säffle, Sweden))">WROPROD4 (Wrocław, Poland (old*/}
      {/*                  Säffle, Sweden))*/}
      {/*                </option>*/}
      {/*                <option value="SAFTEST (Wrocław, Poland (old Säffle, Sweden Test))">SAFTEST*/}
      {/*                  (Wrocław, Poland (old Säffle, Sweden Test))*/}
      {/*                </option></Input>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </>}*/}
      {/*      </CardBody>*/}
      {/*    </Card>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {factoryToBeDeleted ? <FactoryDelete setFactoryToBeDeleted={setFactoryToBeDeleted} factoryToBeDeleted={factoryToBeDeleted} setFactories={setFactories} factories={factories}/> : null}
      <div className="row">
        <div className="col-12">
          <Card className={'chassis-selector'}>
            <CardBody>
              {loading && <p>Loading...</p>}
              {error && <p>Error!!!</p>}
              {data && <>
              <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
                Factories list
              </CardTitle>
                <p>under maintenance</p>
              {/*  {factories &&*/}
              {/*<div style={{ height: `${(rowHeight*pageSize)+160}px`, width: '100%' }}>*/}
              {/*  <DataGrid*/}
              {/*    rows={factories.map((d, i) => ({...d, id: `${i}-${d.id}` /*TODO wait for unique keys form backend*!/)) as any}*/}
              {/*    columns={columns}*/}
              {/*    pageSize={pageSize}*/}
              {/*    disableSelectionOnClick*/}
              {/*    components={{ Toolbar: GridToolbar }}*/}
              {/*    onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}*/}
              {/*    rowsPerPageOptions={[5, 10, 20]}*/}
              {/*  />*/}
              {/*</div>*/}
              {/*  }*/}

              </>}
            </CardBody>
          </Card>
        </div>
      </div>
    </Page>
  );
}