import Axios from "axios";
import { loginRequest } from './authConfig';
import {configure} from "axios-hooks";
import {AccountInfo, IPublicClientApplication} from "@azure/msal-browser";
import {Env} from "./utils";

interface BspMSALContext {
  account: AccountInfo | null;
  instance: IPublicClientApplication;
}

const environment = process.env.REACT_APP_ENVIRONMENT;
const localEnv = process.env.NODE_ENV === 'development';

const baseURL = {
  [Env.qa]: 'https://bspapi-qa.azurewebsites.net',
  [Env.dev]: 'https://bspapidev.azurewebsites.net',
  [Env.prod]: 'https://bspapi.azurewebsites.net',
}

export const apiURL = localEnv ? "/api" : environment ? baseURL[environment] : baseURL[Env.prod];

const bspApi = Axios.create({
  baseURL: apiURL
})

configure({ axios: bspApi })

const bspMSALContext = {} as BspMSALContext;

bspApi.interceptors.request.use(async (config) => {
  if (!bspMSALContext.account) {
    throw Error('No active account! Verify a user has been signed in.');
  }
  if (bspMSALContext.instance) {
    const response = await bspMSALContext.instance.acquireTokenSilent({
      ...loginRequest,
      account: bspMSALContext.account,
    });

    const bearer = `Bearer ${response.accessToken}`;
    if (config.headers) {
      config.headers.Authorization = bearer;
    }
  }

  return config;
});

export { bspApi, bspMSALContext };