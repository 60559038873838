import React, {useEffect, useRef, useState} from 'react';
import './News.css';
import {Card, CardBody, CardSubtitle, CardText, CardTitle} from "reactstrap";
import Button from "@mui/material/Button";
import {faEdit} from '@fortawesome/free-regular-svg-icons'
import {faList} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useAxios, { UseAxiosResult } from "axios-hooks";
import {format, parseISO} from "date-fns";
import {Box, IconButton, Modal, TextareaAutosize, Typography} from "@mui/material";
import ShowMoreText from "react-show-more-text";

interface NewsDTO {
  id: number;
  headline: string;
  info: string;
  timestamp: string;
}

interface NewsPostDTO {
  headline: string;
  info: string;
}

export const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 660,
  maxHeight: '80vh',
  overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  outline: 0
};

const News = (props: any): JSX.Element => {
  const [showAllNews, setShowAllNews] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [news, setNews] = useState<NewsDTO[]>([]);
  const [newNewsTitle, setNewNewsTitle] = useState('');
  const [newNewsBody, setNewNewsBody] = useState('');
  const [newsToBeDeleted, setNewsToBeDeleted] = useState<number | null>(null);
  const [newsToEdit, setNewsToEdit] = useState<NewsDTO | null>(null);
  const refUnusedResponse = useRef<any>(null);
  const [{ data, loading, error }]: UseAxiosResult<NewsDTO[] | undefined, boolean, any> = useAxios('/news');
  const [{ data: newPostData, loading: newPostLoading, error: newPostErrorPost, response: newPostResponse }, executePost]: UseAxiosResult<NewsDTO | undefined, NewsPostDTO, any> = useAxios( {
    url: '/news',
    method: 'PUT'
  }, {manual: true});
  const [{ data: deleteData, loading: deleteLoading, error: deleteError, response: deleteResponse }, executeDelete]: UseAxiosResult<any, any, any> = useAxios( {
    url: '/news',
    method: 'DELETE'
  }, {manual: true});
  const [{ data: editData, loading: editLoading, error: editError, response: editResponse }, executeEdit]: UseAxiosResult<any, any, any> = useAxios( {
    url: '/news',
    method: 'PATCH'
  }, {manual: true});

  useEffect( () => {
    if (editResponse?.data || newPostResponse?.data) {
      refUnusedResponse.current = editResponse?.data || newPostResponse?.data;
    }
  }, [editResponse, newPostResponse])

  useEffect( () => {
    if (data) {
      setNews([...data].sort((a, b) => b.id - a.id));
    }
  }, [data])

  useEffect( () => {
    if (newPostResponse?.data && refUnusedResponse.current && !news.some(n => n.id === newPostResponse?.data?.id)) {
      setNews([newPostResponse.data,...news])
      refUnusedResponse.current = null;
      setNewNewsTitle('');
      setNewNewsBody('');
    }
  }, [newPostResponse, news])

  useEffect( () => {
    if (deleteResponse && newsToBeDeleted !== null) {
      setNews(news.filter(n => n.id !== newsToBeDeleted))
      setNewsToBeDeleted(null);
    }
  }, [deleteResponse, newsToBeDeleted, news])

  useEffect( () => {
    if (newsToEdit && refUnusedResponse.current && editResponse?.data.id === newsToEdit?.id) {
      setNews(news.map(n => n.id === newsToEdit.id ? editResponse.data : n))
      setNewsToEdit(null);
      refUnusedResponse.current = null;
    }
  }, [editResponse, newsToEdit, news])

  const toggleAllNews = () => {
    setShowAllNews(!showAllNews)
  };

  const toggleEdit = () => {
    setShowEdit(!showEdit)
  };

  const addNews = () => {
    if (newNewsTitle && newNewsBody) {
      executePost({
        data: {
          headline: newNewsTitle,
          info: newNewsBody,
        }
      })
    }
  }

  const deleteNews = (id: number) => {
    if (id) {
      executeDelete({
        data: {
          id
        }
      })
      setNewsToBeDeleted(id);
    }
  }

  const editNews = (news: NewsDTO) => {
    if (news) {
      executeEdit({
        data: {
          ...news
        }
      })
    }
  };

  return (
    <Card className={'news'}>
      <CardBody>
        <div style={{display: 'flex'}}>
        <CardTitle tag="h5" className="mb-3" style={{flexGrow: 1}}>
          BSP News
        </CardTitle>
          <Button className={'no-underline'} onClick={toggleEdit}> {/* todo edit, add and remove news based on user role */}
            <FontAwesomeIcon icon={faEdit} />&nbsp;Edit
          </Button>
        </div>
        {loading && <p>Loading...</p>}
        {error && <p>Error!!!</p>}
        {news && news.map(n => <div key={n.id}>
          <CardSubtitle
            className="mb-2"
            tag="h6"
          >
            {`${format(parseISO(n.timestamp), "yyyy-MM-dd")} ${n.headline}`}
          </CardSubtitle>
          <div className={'news-card card-text'} style={{marginBottom: '16px'}}>
            <ShowMoreText anchorClass="show-more-link">{n.info}</ShowMoreText>
          </div>
        </div>)}
        <div className={'view-all'}>
          <Button className={'no-underline'} onClick={toggleAllNews}>
            <FontAwesomeIcon icon={faList} />&nbsp;View All
          </Button>
        </div>
      </CardBody>
      <Modal
        open={showAllNews}
        onClose={toggleAllNews}
        aria-labelledby="news-modal-modal-title"
        aria-describedby="news-modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} >
              <Typography variant="h5" id="modal-modal-description" sx={{ mt: 2 }}>
                News
              </Typography>
            </Box>
            <Box>
              <IconButton className={'close-news-modal no-underline'} onClick={toggleAllNews}>
                &#215;
              </IconButton>
            </Box>
          </Box>
          <table className={'newsTable'}><tbody>
          {news && news.map(n => <tr key={n.id}>
            <td>
                <h6>{`${format(parseISO(n.timestamp), "yyyy-MM-dd")} ${n.headline}`}</h6>
              <p>{n.info}</p></td>
          </tr>)}</tbody></table>
        </Box>
      </Modal>
      <Modal
        open={showEdit}
        onClose={toggleEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box display="flex" alignItems="center" style={{marginBottom: '10px'}}>
            <Box flexGrow={1} >
              <Typography variant="h5" id="modal-modal-title" component="h2">
                Add a news
              </Typography>
            </Box>
            <Box>
              <IconButton className={'close-news-modal no-underline'} onClick={toggleEdit}>
                &#215;
              </IconButton>
            </Box>
          </Box>
          <TextareaAutosize
            aria-label="empty textarea"
            placeholder="Title"
            style={{ width: '100%', marginBottom: '10px' }}
            value={newNewsTitle}
            onChange={(e) => {setNewNewsTitle(e.target.value)}}
          />
          <TextareaAutosize
            aria-label="empty textarea"
            placeholder="Content"
            style={{ width: '100%', marginBottom: '10px' }}
            value={newNewsBody}
            onChange={(e) => {setNewNewsBody(e.target.value)}}
          />
          <div style={{display: 'flex', justifyContent: 'end'}}>
            <Button className={'no-underline'} variant="contained" onClick={ addNews } disabled={!(newNewsTitle && newNewsBody)}>
              save
            </Button>
            <Button className={'no-underline'} onClick={ () => {setNewNewsTitle(''); setNewNewsBody('')} } disabled={!(newNewsTitle || newNewsBody)}>
              cancel
            </Button>
          </div>
          <hr/>
          <Typography variant="h5" id="modal-modal-description" sx={{ mt: 2 }}>
            Edit or remove old news
          </Typography>
          <table className={'newsTable'}><tbody>
          {news && news.map(n => <tr key={n.id}>
            <td>
              {newsToEdit?.id === n.id ? <>
                <h6>{`${format(parseISO(n.timestamp), "yyyy-MM-dd")}`}</h6>
                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder="Content"
                  style={{width: '100%', marginBottom: '10px'}}
                  value={newsToEdit.headline}
                  onChange={(e) => {
                    setNewsToEdit({...newsToEdit, headline: e.target.value})
                  }}
                />
                <TextareaAutosize
                  aria-label="empty textarea"
                  placeholder="Content"
                  style={{width: '100%', marginBottom: '10px'}}
                  value={newsToEdit.info}
                  onChange={(e) => {
                    setNewsToEdit({...newsToEdit, info: e.target.value})
                  }}
                />
                <div style={{display: 'flex', justifyContent: 'end', marginBottom: '10px'}}>
                  <Button onClick={() => {editNews(newsToEdit)}} className={'no-underline'} variant="contained">save</Button>
                </div>
              </>
              : <>
              <h6>{`${format(parseISO(n.timestamp), "yyyy-MM-dd")} ${n.headline}`}</h6>
              <p>{n.info}</p>
            </>}
            </td>
            <td><Button onClick={() => {
              if (newsToEdit?.id === n.id) {
                setNewsToEdit(null)
              }
              else {
               setNewsToEdit(n)}
            }
            } className={'no-underline'}>{newsToEdit?.id === n.id ? 'cancel' : 'edit'}</Button></td>
            <td><Button onClick={() => {deleteNews(n.id)}} className={'no-underline'}>delete</Button></td>
          </tr>)}
          </tbody></table>

        </Box>
      </Modal>
    </Card>
  );
}

export default News;
