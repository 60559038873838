import React, {useContext, useEffect, useState} from 'react';
import './Notifications.css';
import {Card, CardBody, CardTitle, Table} from "reactstrap";
import Button from "@mui/material/Button";
import {faEdit} from '@fortawesome/free-regular-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useAxios, {UseAxiosResult} from "axios-hooks";
import {Alert, Checkbox, List, ListItem} from "@mui/material";
import Loader from "./Loader";
import {format, parseISO} from "date-fns";
import {Link} from "react-router-dom";
import {styles} from "./Home";
import {UserSubscriptionDTO} from "./EmailNotifications";
import {UserInfoContext} from "./App";
import {FactoryDTO} from "./EmailNotifications";

const Notifications = (props: any): JSX.Element => {

  const [subscriptions, setSubscriptions] = useState<UserSubscriptionDTO[]>([]);
  const {userData} = useContext(UserInfoContext);

  const [{
    data,
    loading,
    error
  }]: UseAxiosResult<FactoryDTO[] | undefined, boolean, any> = useAxios('/factory/getFactories');

  const [{
    data: dataSubscriptions,
    loading: loadingSubscriptions,
    error: errorSubscriptions
  }]: UseAxiosResult<UserSubscriptionDTO[] | undefined, boolean, any> = useAxios('/notifications/getsubscriptionsforuser');

  const [{ data: saveSubscriptionsData, loading: saveSubscriptionsLoading, error: saveSubscriptionsError, response: saveSubscriptionsResponse }, executesaveSubscriptions]: UseAxiosResult<any | undefined, any, any> = useAxios( {
    url: `/notifications/savesubscriptions`,
    method: 'POST',
    data: subscriptions,
  }, {manual: true});


  useEffect( ()=> {
    if (dataSubscriptions) {
      setSubscriptions(dataSubscriptions);
    }
  }, [dataSubscriptions])

  return (
    <Card className={'notifications'}>
      <CardBody>
        {(loading || loadingSubscriptions) ? <span>Loading <Loader size={12}/></span> :
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <CardTitle tag="h5" className="mb-3" style={{flexGrow: 1}}>
              Notifications
            </CardTitle>
            <div style={{marginBottom: '6px'}}>Notify me via e-mail of processing results per factory:</div>
            <Table borderless style={{marginBottom: 0, display: 'block', maxWidth: '660px', fontSize: '14px'}}>
              <tbody style={{display: 'table', width: '100%'}}>
              <tr>
                <th style={{paddingLeft: '4px'}}>Factory</th>
                <th style={{textAlign: 'center'}}>NOK</th>
                <th style={{textAlign: 'center'}}>OK</th>
              </tr>
              {data && data.length ? data.map( // todo map over factories; add loading indicators
                (item, index) =>
                  <tr key={item.oasCode.code}
                      style={{backgroundColor: index % 2 === 1 ? 'white' : 'rgba(0, 0, 0, 0.03)'}}>
                    <td style={{verticalAlign: 'middle'}}>
                      {item.oasCode.code}
                    </td>
                    <td style={{textAlign: 'center', verticalAlign: 'middle'}}>
                      <Checkbox checked={!!subscriptions.find(s => s.oascode === item.oasCode.code && s.nok)}
                                onClick={() => {
                                  console.log('nok clicked subscriptions', subscriptions)
                                  console.log('nok mapped subscriptions', subscriptions.map(s => {
                                    if (s.oascode === item.oasCode.code) {
                                      return {
                                        ...s,
                                        nok: !s.nok
                                      }
                                    } else return s;
                                  }))
                                  setSubscriptions(subscriptions.find(s => s.oascode === item.oasCode.code) ? subscriptions.map(s => {
                                    if (s.oascode === item.oasCode.code) {
                                      return {
                                        ...s,
                                        nok: !s.nok
                                      }
                                    } else return s;
                                  }) : [...subscriptions, {
                                    email: userData?.email,
                                    oascode: item.oasCode.code,
                                    //@ts-ignore
                                    ok: false,
                                    nok: true
                                  }])
                                }}/>
                    </td>
                    <td style={{textAlign: 'center', verticalAlign: 'middle'}}>
                      <Checkbox checked={!!subscriptions.find(s => s.oascode === item.oasCode.code && s.ok)}
                                onClick={() => {
                                  console.log('ok clicked subscriptions', subscriptions)
                                  setSubscriptions(subscriptions.find(s => s.oascode === item.oasCode.code) ? subscriptions.map(s => {
                                    if (s.oascode === item.oasCode.code) {
                                      return {
                                        ...s,
                                        ok: !s.ok
                                      }
                                    } else return s;
                                  }) : [...subscriptions, {
                                    email: userData?.email,
                                    oascode: item.oasCode.code,
                                    //@ts-ignore
                                    ok: true,
                                    nok: false
                                  }])
                                }}/>
                    </td>
                  </tr>
              ) : null}

              </tbody>
            </Table>

            <div style={{display: 'flex', justifyContent: 'right', padding: '16px 0 6px 0'}}>
              <Button variant="contained" onClick={() => {
                executesaveSubscriptions();
              }}>
                Update my notification preferences
              </Button>
            </div>
            {/*  <Button className={'no-underline'}>*/}
            {/*    <FontAwesomeIcon icon={faEdit}/>&nbsp;Edit*/}
            {/*  </Button>*/}
            {/*</div>*/}
            {/*<div style={{marginBottom: '20px'}}>*/}
            {/*  <Button variant="contained" style={{marginRight: '12px'}} onClick={() => {*/}
            {/*    subscribeOk()*/}
            {/*  }}>*/}
            {/*    Notify me of ok results*/}
            {/*  </Button>*/}
            {/*  {loadingOk && <Loader size={12} />}*/}
            {/*  {!loadingOk && !errorOk && dataOk !== undefined && <Alert severity="success">You successfully subscribed to ok notifications</Alert>}*/}
            {/*  {!loadingOk && errorOk && <Alert severity="error">An error occurred when subscribing to ok notifications</Alert>}*/}
            {/*</div>*/}
            {/*<div>*/}
            {/*  <Button variant="contained" style={{marginRight: '12px'}} onClick={() => {*/}
            {/*    subscribeNok()*/}
            {/*  }}>*/}
            {/*    Notify me of nok results*/}
            {/*  </Button>*/}
            {/*  {loadingNok && <Loader size={12} />}*/}
            {/*  {!loadingNok && !errorNok && dataNok !== undefined && <Alert severity="success">You successfully subscribed to nok notifications</Alert>}*/}
            {/*  {!loadingNok && errorNok && <Alert severity="error">An error occurred when subscribing to nok notifications</Alert>}*/}
          </div>
        }
      </CardBody>
    </Card>
  );
}

export default Notifications;
