import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import './FilesDownload.css';
import {Card, CardBody, CardTitle, Input, Table} from "reactstrap";
import useAxios, {UseAxiosResult} from "axios-hooks";
import Page from "./Page";
import {
  Autocomplete,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel, FormGroup,
  TextField,
  Typography
} from "@mui/material";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';

import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import {format, parseISO} from "date-fns";
import {DatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Button from "@mui/material/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCopy, faEdit} from "@fortawesome/free-regular-svg-icons";
import {faChevronDown, faDownload, faChevronRight, faTimes} from "@fortawesome/free-solid-svg-icons";
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import {apiURL, bspApi} from "./apiCalls";
import set from "lodash/set";
import get from "lodash/get";
import Loader from "./Loader";
import { eachDeep, someDeep } from 'deepdash-es/standalone';


interface ChassisDTO {
  chassisId: string;
  chassisNo: number;
}

interface IncomingOrderDTO {
  chassisId: string
  chassisNo: number;
  timestamp: string; // ISO 8601
  queueCorrelationId: string;
  transactionId: string;
}

const chassisListMock = [// TODO use data
  { chassisId: 'B8R', chassisNo: 151200, group: 'Last NOK Processed' },
  { chassisId: 'B8R', chassisNo: 151201, group: 'Last NOK Processed' },
  { chassisId: 'B8R', chassisNo: 151202, group: 'Last OK Processed' },
  { chassisId: 'B8R', chassisNo: 151203, group: 'Last OK Processed' },
  { chassisId: 'B8R', chassisNo: 151204, group: 'Recently Processed' },
  { chassisId: 'B8R', chassisNo: 151205, group: 'Recently Processed' },
].map(chassis => ({...chassis, label: `${chassis.chassisId} ${chassis.chassisNo}`}));

const isNotTechnicalProp = (s: string) => s !== '*checked' && s !== '*path' && s !== '*value';

const transform = (input: any) => {
  const obj = {...input};
  const iterate = (o: any) => {
    Object.keys(o).forEach(key => {
      if (isNotTechnicalProp(key)) {
        if (typeof o[key] === 'object' && o[key] !== null) {
          o[key] = {
            ...o[key],
            ['*path']: `${o['*path'] ? `${o['*path']}` : ''}${key}`
          }

        } else {
          o[key] = {
            ['*value']: key.endsWith('/') ? undefined : key,
            ['*path']: `${o['*path'] ? `${o['*path']}` : ''}${key}`,
            '*checked': o && o['*checked'] === 1 ? 1 : 0
          }
        }

        if (key.endsWith('/')) {
          iterate(o[key])
        }
      }
    })
  }
  iterate(obj);
  return obj;
}

const prepareParams = (input: string) => {
  const regex = /\//;
  return input.replace(regex, `&prefix=`);
}

const lastDirFromPath = (path: string) => {
  if (path) {
    const items = path.split('/').filter(p => p);
    if (items[items.length-1]) {
      return `${items[items.length-1]}/`
    }
  }
  return ''
}

const renderTree = (tObj: any, callback: any, multipleFilesDownloadHandler: any, multipleFilesDownloadState: any, selectHandler: any): any => {
  const objKeys = Object.keys(tObj);

  if (objKeys[0] && Object.keys(tObj[objKeys[0]]).length === 3 && tObj[objKeys[0]]['*value'] && tObj[objKeys[0]]['*path']) {
    return <>
      <div key={tObj[objKeys[0]]['*value']}>
        <a key={`${tObj[objKeys[0]]['*value']}-link`} style={{marginLeft: '32px'}}
           title={`Download file ${tObj[objKeys[0]]['*value']}`}
           href="javascript:void(0)"
           onClick={(e) => { // TODO loading indicator
             e.stopPropagation();
             const path = prepareParams(tObj[objKeys[0]]['*path']);
             bspApi
               .get(`/filesdownload/downloadfiles?containerName=${path.split('&prefix=')[0]}&prefix=${path.split('&prefix=')[1]}`,
                 {
                   method: 'GET',
                   responseType: 'blob'
                 })
               .then(resp => {
                 console.log('GET resp', resp)
                 const filename = path.split('&prefix=')[1].split('/').at(-1) || 'bsp-file';
                 const blob = new Blob([resp.data], { type: 'arraybuffer' });
                 const link = document.createElement('a');
                 link.href = window.URL.createObjectURL(blob);
                 link.download = filename;
                 document.body.appendChild(link);
                 link.click();
                 document.body.removeChild(link);
               })
               .catch(e => {
                 console.error('error  downloading single file')
               })
           }}
           >{tObj[objKeys[0]]['*value']}</a>
        <Checkbox
          key={`${tObj[objKeys[0]]['*value']}-checkbox`}
          checked={tObj[objKeys[0]]['*checked'] === 1}
          indeterminate={tObj[objKeys[0]]['*checked'] === 0.5}
          onChange={ (e) => {
            e.stopPropagation();
            // multipleFilesDownloadHandler(prepareParams(tObj[objKeys[0]]['*path']))
            selectHandler(prepareParams(tObj[objKeys[0]]['*path']));
            }
          }
        />
      </div>
    </>
  }

  if (objKeys.length === 3 && !tObj['*value'] && tObj['*path']) {
    return <TreeItem key={tObj['*path']} nodeId={tObj['*path']} label={lastDirFromPath(tObj['*path'])} onClick={() => callback(tObj['*path'])}/>
  }

  return objKeys.filter(isNotTechnicalProp).map((key: string) => {
      if (tObj[key]) {
        return (
          <TreeItem key={tObj[key]['*value'] ? tObj[key]['*path'] + tObj[key]['*value']: tObj[key]['*path']} nodeId={tObj[key]['*path']}
                    label={
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{ padding: '3px 0'}} onClick={() => callback(tObj[key]['*path'])}>{key}</div>
                        <a
                          style={{color: "#1565c0"}}
                          title={`Download directory ${key}`}
                          onClick={(e) => { // TODO loading indicator
                            e.stopPropagation();
                            const path = prepareParams(tObj[key]['*path']);
                            const params = {
                              ContainerName: path.split('&prefix=')[0],
                              Prefix: path.split('&prefix=')[1]
                            };
                            bspApi
                              .post(`/filesdownload/downloadfiles`,
                                [params],
                                {
                                method: 'POST',
                                responseType: 'arraybuffer'
                              })
                              .then(resp => {
                                let filename;
                                try {
                                  filename = resp.headers['content-disposition'].split(';')[1].trim().split('=')[1]
                                }
                                catch (e) {
                                  console.error('multipleFilesResponse.headers[\'content-disposition\']', e)
                                  filename = 'bsp-file-download.zip';
                                }
                                const blob = new Blob([resp.data], { type: 'arraybuffer' });
                                const link = document.createElement('a');
                                link.href = window.URL.createObjectURL(blob);
                                link.download = filename;
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                              })
                              .catch(e => {
                                console.error('error  downloading single directory')
                              })
                          }}>
                          {isRootLevel(tObj[key]['*path']) ? null :
                            <FontAwesomeIcon
                              key={tObj[key]['*value'] ? tObj[key]['*path'] + tObj[key]['*value'] : tObj[key]['*path']}
                              style={{marginLeft: '6px'}} icon={faDownload}/>
                          }
                        </a>
                        {isRootLevel(tObj[key]['*path']) ? null :
                          <Checkbox
                            style={{ padding: '3px 6px'}}
                            // checked={!!multipleFilesDownloadState.includes(prepareParams(tObj[objKeys[0]]['*path']))}
                            disabled={isRootLevel(tObj[key]['*path'])}
                            color="default"
                            checked={tObj[key]['*checked'] === 1}
                            indeterminate={tObj[key]['*checked'] === 0.5}
                            onChange={(e) => {
                              e.stopPropagation();
                              // multipleFilesDownloadHandler(prepareParams(tObj[objKeys[0]]['*path']))
                              selectHandler(prepareParams(tObj[key]['*path']));
                            }}
                          />
                        }
                      </div>}
          >
            {
              Object.keys(tObj[key]).filter(isNotTechnicalProp).map(k => {
                return renderTree({[k]: tObj[key][k]}, callback, multipleFilesDownloadHandler, multipleFilesDownloadState, selectHandler);
              })
            }
          </TreeItem>
        )
      } else {
        return null;
      }
    }
  )
}

const setInitialChecked = (tree: any) => {
  let newTree = {...tree};
  Object.keys(tree).map(k => {
    newTree[k] = {...newTree[k], '*checked': 0}
  })
  return newTree;
}

const isRootLevel = (path: string) => path.indexOf('/') === path.length-1;

const splitPreservingSlashes = (s: string) => s.match(/.*?\/|.+$/g) ?? []

const treeToArrayOfSelected = (obj: any): any[] => {
  const selected: any[] = [];
  eachDeep(obj, (value, key, parentValue, context) => {
    if (typeof key === 'string' && isNotTechnicalProp(key) && Object.keys(value).every(k => !isNotTechnicalProp(k))) {
      // 'all keys are technical' i.e. it's a file or a dir with no children loaded
      if (value['*checked'] === 1) {
        selected.push(value);
      }
    }
  });
  return selected;
}

const getAllDescendants = (obj: any): any[] => {
  const descendants: any[] = [];
  eachDeep(obj, (value, key, parentValue, context) => {
    if(key && isNotTechnicalProp(String(key))) {
      descendants.push(value);
    }
  });
  return descendants;
}

const selectedToDownloadParams = (arr: any[]) => {
  return arr.map(obj => {
    const path = prepareParams(obj['*path']);
    return {
      ContainerName: path.split('&prefix=')[0],
      Prefix: path.split('&prefix=')[1]
    }
  })
}

export function FilesDownload() {

  const [currentTemplate, setCurrentTemplate] = useState<null | ChassisDTO>(null);
  const [expanded, setExpanded] = useState('panel1');
  const [pageSize, setPageSize] = useState(5);
  const [mode, setMode] = useState('browse');
  const [isEditingTemplate, setIsEditingTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [currentChassis, setCurrentChassis] = useState<null | ChassisDTO[]>(null);
  const [tree, setTree] = useState<any>({});
  const [currentPath, setCurrentPath] = useState('');
  const [multipleFilesDownloadState, setMultipleFilesDownloadState] = useState<string[]>([]);

  const [{ data, loading, error }]: UseAxiosResult<ChassisDTO[] | undefined, boolean, any> = useAxios('/bodyorder/chassislist'); // TODO returns 6x {chassisId: null, chassisNo: 0}
  const [{ data: dataDetails, loading: loadingDetails, error: errorDetails }]: UseAxiosResult<IncomingOrderDTO[] | undefined, boolean, any> = useAxios(`/orders/getorderlist?chassisType=${'B8R'}`); // TODO should receive chassisID and return an array with historical values
  const [{ data: dataOkchassislist, loading: loadingOkchassislist, error: errorOkchassislist }]: UseAxiosResult<ChassisDTO[] | undefined, boolean, any> = useAxios('/bodyorder/okchassislist'); // TODO is it one or many?
  const [{ data: dataNokchassislist, loading: loadingNokchassislist, error: errorNokchassislist }]: UseAxiosResult<ChassisDTO[] | undefined, boolean, any> = useAxios('/bodyorder/nokchassislist'); // TODO is it one or many?
  const [{ data: dataLastchassislist, loading: loadingLastchassislist, error: errorLastchassislist }]: UseAxiosResult<ChassisDTO[] | undefined, boolean, any> = useAxios('/bodyorder/lastchassislist'); // TODO is it one or many?
  const [{ data: dataContainerList, loading: loadingContainerList, error: errorContainerList }]: UseAxiosResult<any | undefined, boolean, any> = useAxios('filesdownload/getcontainerlist');
  // DIR ends wih / http://localhost:5000/filesdownload/getfiles?containerName=b0e&prefix=185904/2022-07-20T10:31:47/
  // FILE no trailing / http://localhost:5000/filesdownload/getfiles?containerName=b0e&prefix=185904/2022-07-20T10:31:47/tmp/ba/html/FN
  const [{ data: newGetData, loading: newGetLoading, error: newGetError, response: newGetResponse }, executeGet]: UseAxiosResult<any | undefined, any, any> = useAxios( {
    url: `/filesdownload/getfiles?containerName=${currentPath ? prepareParams(currentPath) : ''}`,
    method: 'GET'
  }, {manual: true});

  const multipleFilesResponseRef = useRef(false);
  const [{ data: multipleFilesData, loading: multipleFilesLoading, error: multipleFilesError, response: multipleFilesResponse }, executeMultipleFiles]: UseAxiosResult<any | undefined, any, any> = useAxios( {
    url: `/filesdownload/downloadfiles`,
    method: 'POST',
    data: selectedToDownloadParams(treeToArrayOfSelected(tree)),
    responseType: 'arraybuffer'

  }, {manual: true});

  /*
  * download using checkboxes
  * /filesdownload/downloadfiles
    body:
    [
      {
        containerName: 'xyz',
        prefix: 'abc'
      },
      {
        containerName: 'ijk',
        prefix: 'opr'
      },
    ...
    ]
  * */

  useEffect(() => {
    if (dataContainerList && Array.isArray(dataContainerList)) {
      // console.info('setting tree')
      setTree(setInitialChecked(transform(dataContainerList.map(container => (`${container}/`)).reduce((o: any, key: string) => ({ ...o, [key]: undefined}), {}))));
    }
  }, [dataContainerList])

  useEffect(() => {
    if (currentPath) {
      executeGet();
    }
  }, [currentPath])

  useEffect(() => {
    const regexCont = /^\w+\//;
    const newValue = Array.isArray(newGetData) ? newGetData.map( (d: string) => d.replace(currentPath.replace(regexCont, ''), '')) : newGetData;

    const transformedPath =       currentPath
      .split('/')
      .filter(p => p)
      .map(p => `${p}/`)
      .join('.');

    const treeBefore = set( // todo mark added files as checked if their parent is checked
      {
        ...tree
      },
      transformedPath,
      Array.isArray(newValue) ?
        newValue
          .reduce((o: any, key: string) => ({
            ...o,
            ...get(tree, transformedPath),
            [key]: undefined
          }), {})
        : newValue
    );
    console.info('tree before transform', treeBefore)
    // todo place for perf opt as the transform is done each time on full tree
    setTree(transform(treeBefore))
  }, [newGetData])

  const filesToDownload = useMemo(() => treeToArrayOfSelected(tree).length
  , [tree])

  const onChange = (currentNode: any, selectedNodes: any) => {
    console.log('onChange::', currentNode, selectedNodes)
  }
  const onAction = (node: any, action: any) => {
    console.log('onAction::', action, node)
  }
  const onNodeToggle = (currentNode: any) => {
    console.log('onNodeToggle::', currentNode)
  }

  const handleClickOnContainer = (container: string) => {
    setCurrentPath(container);
  }

  const multipleFilesDownloadHandler = (file: string) => {
    if (multipleFilesDownloadState.includes(file)) {
      setMultipleFilesDownloadState(multipleFilesDownloadState.filter(f => f !== file))
    } else {
      setMultipleFilesDownloadState([...multipleFilesDownloadState, file])
    }
  }

  const hasChildren = (obj: any) => Object.keys(obj).filter(isNotTechnicalProp).length > 0

  const selectHandler = useCallback((file: string) => {
    console.log('selectHandler file', [file.split('&prefix=')[0]+'/', ...splitPreservingSlashes(file.split('&prefix=')[1])])
    const pathChunks = [file.split('&prefix=')[0]+'/', ...splitPreservingSlashes(file.split('&prefix=')[1])];
    const selectedObj = get(tree, pathChunks);
    console.log('selectedObj before', selectedObj)
    let updatedTree = {...tree};
    updatedTree = set(updatedTree, [...pathChunks, '*checked'], get(tree, [...pathChunks, '*checked']) ? 0 : 1); // toggle checked of the clicked item

    const toggleDescendants = (obj: any) => {
      if (hasChildren(obj)) { // todo now it's mutable function
        // console.log('hasChildren')
        for (const [key, value] of Object.entries(obj)) {
          if (isNotTechnicalProp(key)) {
            // console.log('isNotTechnicalProp')
            obj[key] = {...obj[key], '*checked': obj['*checked']}
          }

          // console.log('obj[key]', obj[key])
          if (obj[key] && Object.keys(obj[key]).some(k => {
            // console.log('k', k)
            // console.log('obj[key][k]', obj[key][k])
            return obj[key][k]?.hasOwnProperty('*checked')
          })) {
            // console.log('need to toggle this obj', obj[key])
            toggleDescendants(obj[key]);
          }
          // console.log('obj[key]', obj[key]);
        }
      }
    }
    toggleDescendants(selectedObj);

    console.log('selectedObj after', selectedObj)
    updatedTree = set(updatedTree, pathChunks, selectedObj);

    const markIndeterminate = (obj: any) => {
      eachDeep(obj, (value, key, parentValue, context) => {
        // console.warn('markIndeterminate obj', obj)
        // console.warn('markIndeterminate key', key)
        // console.warn('markIndeterminate value', value)
        if (
          key &&
          isNotTechnicalProp(String(key)) &&
          someDeep(value, (v, k, pV, c) => k === '*checked' && v === 1, {includeRoot: true})
        ) {
          //@ts-ignore
          // if (get(updatedTree, context.path)['*checked'] !== 1) {
            //@ts-ignore
            updatedTree = set(updatedTree, context.path, {...get(updatedTree, context.path), '*checked': 0.5})
          // }
          //@ts-ignore
          if (getAllDescendants(value).every(o => o['*checked'] === 1)) {
            //@ts-ignore
            updatedTree = set(updatedTree, context.path, {...get(updatedTree, context.path), '*checked': 1})
          }
        }
      }, {includeRoot: true});

    }
    markIndeterminate(updatedTree);

    setTree(updatedTree);
  }, [tree])

  useEffect( () => {
    console.log('multipleFilesDownloadState', multipleFilesDownloadState)
    if (multipleFilesResponse && multipleFilesResponseRef.current) {
      let filename;
      try {
        filename = multipleFilesResponse.headers['content-disposition'].split(';')[1].trim().split('=')[1]
      }
      catch (e) {
        console.error('multipleFilesResponse.headers[\'content-disposition\']', e)
        filename = 'bsp-file-download.zip';
      }
      const blob = new Blob([multipleFilesResponse.data], { type: 'arraybuffer' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setMultipleFilesDownloadState([]);
      multipleFilesResponseRef.current = false;
    }
  }, [multipleFilesResponse, multipleFilesDownloadState])

  return(
    <Page title={'BSP Files Download'}>
      <div className="row">
        {/*{console.log('data', data)}*/}
        {/*{console.log('dataDetails', dataDetails)}*/}
        {/*{console.log('dataOkchassislist', dataOkchassislist)}*/}
        {/*{console.log('dataNokchassislistv', dataNokchassislist)}*/}
        {/*{console.log('dataLastchassislist', dataLastchassislist)}*/}
        {/*{console.log('currentChassis', currentChassis)}*/}
        {console.log('dataContainerList', dataContainerList)}
        {console.log('tree', tree)}
        <div className="col-12">
          <Card className={'chassis-selector'}>
            <CardBody>
              {loading && <p>Loading...</p>}
              {error && <p>Error!!!</p>}
              {data && <>
                <ButtonGroup aria-label="outlined button group">
                  <Button variant={mode === 'browse' ? "contained" : "outlined"} onClick={() => {setMode('browse')}}>File Browse Mode Selection</Button>
                  {/*<Button variant={mode === 'predefined' ? "contained" : "outlined"} onClick={() => {setMode('predefined')}}>Pre-defined Set Selection</Button>*/}
                </ButtonGroup>
                <div style={{marginTop: '20px'}}/>
                {mode === 'predefined' ? <>

                    <Dialog onClose={()=>{setIsEditingTemplate(false)}} open={isEditingTemplate}>
                      <DialogTitle>{`Edit ${selectedTemplate} File Set`}</DialogTitle>
                      <div style={{minWidth: '600px', minHeight: '400px', padding: '0 20px 20px 20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>

                        {
                          dataContainerList ?
                            <TreeView
                              aria-label="file system navigator"
                              defaultCollapseIcon={<FontAwesomeIcon icon={faChevronDown}/>}
                              defaultExpandIcon={<FontAwesomeIcon icon={faChevronRight}/>}
                              sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                            >
                              <TreeItem nodeId="1" label="Applications">
                                <TreeItem nodeId="2" label="Calendar" />
                              </TreeItem>
                              <TreeItem nodeId="5" label="Documents">
                                <TreeItem nodeId="10" label="OSS" />
                                <TreeItem nodeId="6" label="MUI">
                                  <TreeItem nodeId="8" label="index.js" />
                                </TreeItem>
                              </TreeItem>
                            </TreeView>
                            : null
                        }

                        <div style={{textAlign: "right"}}>
                          <Button onClick={()=>{ setIsEditingTemplate(false)}}>
                            Cancel {/*TODO  */}
                          </Button>
                          <Button variant="outlined" className={'no-underline'} >
                            Save as {/*TODO  */}
                          </Button>
                        </div>
                      </div>
                    </Dialog>
                    <div className="row">
                      <div className="col-3">
                        <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
                          Body Developer Templates
                        </CardTitle>

                        <Autocomplete
                          disablePortal
                          open
                          id="combo-box-demo"
                          options={['Jeremi template 1',
                            'Jeremi template 2',
                            'Wojciech template 1',
                            'Wojciech template 2',
                            'Wojciech template 3',
                            'Wojciech template 4']}
                          renderInput={(params) =>
                            <TextField {...params} label="Select or Search Template"/>
                          }
                          onChange={(event, value) => {
                            setSelectedTemplate(value as string);
                          }}
                        />
                      </div>
                      <div className="col-9">
                        {selectedTemplate ? <Table borderless style={{marginBottom: 0, width: '600px', marginLeft: '24px', whiteSpace: 'nowrap', verticalAlign: 'middle', marginTop: '30px'}}>
                          <tbody>
                          <tr style={{fontWeight: '700', backgroundColor: '#F4F4F4'}}>{/* TODO align with tables of content */}
                            <th style={{fontSize: '14px', }}>
                              <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
                                {selectedTemplate} File Set
                              </CardTitle>
                            </th>
                            <th style={{fontSize: '14px', textAlign: 'right'}}>
                              <Button className={'no-underline'} onClick={() => {setIsEditingTemplate(true)}}>
                                <FontAwesomeIcon icon={faEdit} />&nbsp;Edit {/*TODO  */}
                              </Button>
                              <Button className={'no-underline'}>
                                <FontAwesomeIcon icon={faTimes} />&nbsp;{/*TODO  */}
                              </Button>
                            </th>
                          </tr>
                          <tr>
                            <td style={{fontSize: '14px', }} colSpan={2}>
                              (ChType)\(ChNo)\(TimeStamp)\htm\*.*
                            </td>
                          </tr>
                          <tr style={{backgroundColor: '#F4F4F4'}}>
                            <td style={{fontSize: '14px', }} colSpan={2}>
                              (ChType)\(ChNo)\(TimeStamp)\htm\ppout_AlCcref.xml
                            </td>
                          </tr>
                          <tr>
                            <td style={{fontSize: '14px', }} colSpan={2}>
                              (ChType)\(ChNo)\(TimeStamp)\htm\ppout_(ChDL)BSP_(Timestamp mpL210.xml
                            </td>
                          </tr>
                          <tr style={{backgroundColor: '#F4F4F4'}}>
                            <td style={{fontSize: '14px', }} colSpan={2}>
                              (ChType)\(ChNo)\(TimeStamp)\htm\ppout_(ChDL)_AICcref.xml
                            </td>
                          </tr>
                          </tbody>
                        </Table> : null}
                      </div>
                    </div>


                    <div style={{marginTop: '20px'}} className="row">
                      <div className="col-3">
                        <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
                          Chassis ID
                        </CardTitle>

                        <Autocomplete
                          disablePortal
                          open
                          multiple
                          // sx={{ height: 400 }}
                          // ListboxProps={{ style: { height: "600px" } }}
                          id="combo-box-demo"
                          options={chassisListMock}
                          renderInput={(params) => <TextField {...params} label="Select or Search Chassis ID for Template" />}
                          onChange={(event, value) => {setCurrentChassis(value);console.log('selected chassis', value)}}
                        />


                      </div>
                      <div className="col-9">
                        <Table borderless style={{marginBottom: 0, width: '600px', marginLeft: '24px', whiteSpace: 'nowrap', verticalAlign: 'middle', marginTop: '30px'}}>
                          <tbody>
                          <tr style={{fontWeight: '700', backgroundColor: '#F4F4F4'}}>{/* TODO align with tables of content */}
                            <th style={{fontSize: '14px', }} colSpan={2}>
                              <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
                                Selected and ready to download
                              </CardTitle>
                            </th>
                          </tr>
                          <tr>
                            <td style={{fontSize: '14px', }} colSpan={1}>
                              (B8R)\(185124)\(2021-10-05 14-03-18)\htm\*.*
                            </td>
                            <td style={{fontSize: '14px', }} colSpan={1}>
                              <Button className={'no-underline'}>
                                <FontAwesomeIcon icon={faTimes} />&nbsp;{/*TODO  */}
                              </Button>
                            </td>
                          </tr>
                          <tr style={{backgroundColor: '#F4F4F4'}}>
                            <td style={{fontSize: '14px', }} colSpan={1}>
                              (B8R)\(185127)\(2021-10-05 14-03-18)\htm\ppout_AlCcref.xml                          (
                            </td>
                            <td style={{fontSize: '14px', }} colSpan={1}>
                              <Button className={'no-underline'}>
                                <FontAwesomeIcon icon={faTimes} />&nbsp;{/*TODO  */}
                              </Button>
                            </td>
                          </tr>
                          <tr>
                            <td style={{fontSize: '14px', }} colSpan={1}>
                              (B8R)\(185130)\(2021-10-05 14-03-18)\htm\ppout_(ChDL)BSP_(2021-10-05 14-03-18 mpl210.xml
                            </td>
                            <td style={{fontSize: '14px', }} colSpan={1}>
                              <Button className={'no-underline'}>
                                <FontAwesomeIcon icon={faTimes} />&nbsp;{/*TODO  */}
                              </Button>
                            </td>
                          </tr>
                          <tr style={{backgroundColor: '#F4F4F4'}}>
                            <td style={{fontSize: '14px', }} colSpan={1}>
                              (B8R)\(185130)\(2021-10-05 14-03-18)\htm\ppout_(ChDL)_AICcref.xml
                            </td>
                            <td style={{fontSize: '14px', }} colSpan={1}>
                              <Button className={'no-underline'}>
                                <FontAwesomeIcon icon={faTimes} />&nbsp;{/*TODO  */}
                              </Button>
                            </td>
                          </tr>
                          <tr style={{backgroundColor: '#F4F4F4'}}>
                            <td style={{fontSize: '14px', }} colSpan={2}>
                              {currentChassis?.length ?
                                <p>{currentChassis?.length} files selected</p> : null
                              }
                              <FormGroup>
                                <FormControlLabel control={<Checkbox defaultChecked />} label="Finish download after first error" />
                              </FormGroup>
                              <Button onClick={()=>{ setIsEditingTemplate(false)}}>
                                Remove sent items {/*TODO  */}
                              </Button>
                              <Button onClick={()=>{ setIsEditingTemplate(false)}}>
                                Remove all items {/*TODO  */}
                              </Button>
                              <Button variant="outlined" className={'no-underline'} >
                                Download {/*TODO  */}
                              </Button>
                            </td>
                            {/*<td style={{fontSize: '14px', }} colSpan={1}>*/}
                            {/*  <Button className={'no-underline'}>*/}
                            {/*    <FontAwesomeIcon icon={faTimes} />&nbsp;TODO  */}
                            {/*  </Button>*/}
                            {/*</td>*/}
                          </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div> </>
                  : <div style={{minWidth: '600px', minHeight: '600px', padding: '0 20px 20px 20px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                    {loadingContainerList || newGetLoading ? <div style={{height: '30px', width: '395px', paddingRight: '20px', display: 'flex', alignItems: 'center',
                      justifyContent: 'center', justifyItems: 'center'}}><div>Loading <Loader size={12}/></div></div> : <div style={{height: '30px'}}></div>}
                    {                        dataContainerList ?
                      <>
                        <TreeView
                        aria-label="file system navigator"
                        defaultCollapseIcon={<FontAwesomeIcon icon={faChevronDown}/>}
                        defaultExpandIcon={<FontAwesomeIcon icon={faChevronRight}/>}
                        sx={{ height: 360, flexGrow: 1, maxWidth: '395px', overflowY: 'auto' }}
                      >
                        {/*{Object.keys(tree).map((container: string) => {*/}

                        {/*  return (*/}
                        {/*  <TreeItem key={container} nodeId={container} label={container} onClick={() => handleClickOnContainer(container)}>*/}
                        {/*    {tree[container] ? Object.keys(tree[container]).map((item: string) => <TreeItem key={item} nodeId={item} label={item} onClick={() => handleClickOnContainer(item)}/>) : null}*/}
                        {/*  </TreeItem>*/}
                        {/*  )*/}
                        {/*}*/}
                        {/*)}*/}
                         {renderTree(tree, handleClickOnContainer, multipleFilesDownloadHandler, multipleFilesDownloadState, selectHandler)}
                      </TreeView>
                      {filesToDownload ? <Button style={{maxWidth: '395px', marginTop: '12px'}} variant={'contained'} onClick={() => {executeMultipleFiles(); multipleFilesResponseRef.current = true}}>download files</Button> : null}
                      </>
                      : null
                    }
                  </div>}

              </>}
            </CardBody>
          </Card>

        </div>
      </div>
    </Page>
  );
}