import React, {useCallback, useEffect, useState} from "react";
import './EmailNotifications.css';
import {Card, CardBody, CardTitle, Table} from "reactstrap";
import useAxios, {UseAxiosResult} from "axios-hooks";
import Page from "./Page";
import {Alert, Box, Checkbox, Chip, Modal, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {bspApi} from "./apiCalls";
import {Accordion, AccordionDetails, AccordionSummary} from "./Accordion";
import Loader from "./Loader";

interface Country {
  name: string;
}

interface OasCode {
  code: string;
}

export interface FactoryDTO {
  description: string;
  id: number;
  oasCode: OasCode;
  plantCode: string;
  country: Country;
  shortDescription: string;
}

export interface UserSubscriptionDTO {
  email: string;
  oascode: string;
  ok: boolean;
  nok: boolean;
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 660,
  maxHeight: '80vh',
  overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  outline: 0
};

const emailRegex = /^\S+@\S+/;

export function EmailNotifications() {
  const [subscriptions, setSubscriptions] = useState<UserSubscriptionDTO[]>([]);
  const [subscriptionToBeDeleted, setSubscriptionToBeDeleted] = useState<any>(undefined); // subscription, removeFrom: nok | ok
  const [newEmail, setNewEmail] = useState<any>({});
  const [checked, setChecked] = useState<any>({});
  const [expanded, setExpanded] = useState<string | false>(false);
  const [updating, setUpdating] = useState<boolean>(false);
  const [createError, setCreateError] = useState<any>();
  const [deleteError, setDeleteError] = useState<any>();

  const [{
    data: dataSubscriptions,
    loading: loadingSubscriptions,
    error: errorSubscriptions
  }]: UseAxiosResult<UserSubscriptionDTO[] | undefined, boolean, any> = useAxios('/notifications/getallsubscriptions');

  const [{
    data,
    loading,
    error
  }]: UseAxiosResult<FactoryDTO[] | undefined, boolean, any> = useAxios('/factory/getFactories');

  useEffect(() => {
    if (data && !Object.keys(checked).length) {
      setChecked(
        data.reduce((acc, curr, index, arr) => {
          return acc = {
            ...acc,
            [curr.id]: {
              ok: true,
              nok: true
            }
          }
        }, {})
      )
    }
  }, [data])

  useEffect(() => {
    if (dataSubscriptions) {
      setSubscriptions(dataSubscriptions);
    }
  }, [dataSubscriptions])

  const handleDelete = ({subscription, removeFrom}: any) => {
    setSubscriptionToBeDeleted({subscription, removeFrom});
  };

  const handleChange = ({email, factoryId}: any) => {
    if (email) {
      setNewEmail({[factoryId]: email});
    } else {
      setNewEmail({[factoryId]: ''});
    }
  };

  const createNewSubscription = useCallback(({subscription, id}: any) => {
    setUpdating(true);
    setCreateError(undefined);
    bspApi
      .post('/notifications/addsubscriptionforuser', {
        ...subscription
      })
      .then(resp => {
        setSubscriptions([...subscriptions.filter(sub => !(sub.oascode === subscription.oascode && sub.email === subscription.email)), subscription]);
        setNewEmail({
          ...newEmail,
          [id]: ''
        });
        setUpdating(false);
      })
      .catch(e => {
        setUpdating(false);
        setCreateError(e);
      })
  }, [subscriptions])

  const rowHeight = 51;

  const deleteRequest = useCallback(({subscription, removeFrom}) => {
    setDeleteError(undefined);
    let subReqPayload = {...subscription};
    if (subscription.nok && subscription.ok && removeFrom === "ok") {
      subReqPayload = {
        ...subscription,
        nok: true,
        ok: false
      }
    }
    else if (subscription.nok && subscription.ok && removeFrom === "nok") {
      subReqPayload = {
        ...subscription,
        nok: false,
        ok: true
      }
    }
    else {
      subReqPayload = {
        ...subscription,
        nok: false,
        ok: false
      }
    }
    setUpdating(true);
    bspApi
      .delete('/notifications/deletesubscriptionforuser', {
        data: subReqPayload
      })
      .then(resp => {setSubscriptions([...subscriptions.filter(oldSub => {
        return !(oldSub.email === subscription.email && oldSub.oascode === subscription.oascode)
      }), {...subscription, ...subReqPayload}])
        setUpdating(false);
      })
      .catch(e => {
        setUpdating(false);
        setDeleteError(e);
      })
  }, [subscriptions])

  const handleChangeAccordion =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(panel);
    };

  return (
    <Page title={'Email notifications'}>
      <div className="row" style={{marginBottom: '24px'}}>
        <div className="col-12">
          <Card>
            <CardBody>
              {loading && <p>Loading...</p>}
              {error && <p>Error!!!</p>}
              {data && <>
                <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
                  Manage subscriptions to compilation results per factory
                </CardTitle>
                {data && data.sort((a, b) => `${a.description}${a.oasCode}`.localeCompare(`${b.description}${b.oasCode}`)).map(
                  (item, index) =>
                    // @ts-ignore
                    <Accordion className={'emails-accordion'} key={item.id} expanded={expanded === `panel${item.id}`} onChange={handleChangeAccordion(expanded === `panel${item.id}` ? '' : `panel${item.id}`)}>
                      <AccordionSummary
                        // expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        // @ts-ignore
                        id="panel1bh-header"
                      >
                        <p style={{color: 'rgb(33, 37, 41)', fontWeight: 500, marginBottom: 0}}>{`${item.description} (${item.shortDescription})`}</p>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div key={item.id}
                             // style={{backgroundColor: index % 2 === 1 ? 'white' : 'rgba(0, 0, 0, 0.03)'}}
                        >
                          <div className={'add-email-address'} style={{display: 'flex', padding: '24px 30px 30px 30px', alignItems: 'center'}}>
                            <h6 style={{marginRight: '16px'}}>Add email address to following subscription lists:</h6>
                            <div>
                              ok <Checkbox
                              checked={checked[item.id]?.ok ?? false}
                              onChange={ () => {
                                setChecked(
                                  {
                                    ...checked,
                                    [item.id]: {
                                      ...checked[item.id],
                                      ok: !checked[item.id]?.ok
                                    }
                                  }
                                )
                                }
                              }
                            />
                            </div>
                            <div style={{marginRight: '6px'}}>
                              nok <Checkbox
                              checked={checked[item.id]?.nok ?? false}
                              onChange={() => {
                                setChecked(
                                  {
                                    ...checked,
                                    [item.id]: {
                                      ...checked[item.id],
                                      nok: !checked[item.id]?.nok
                                    }
                                  }
                                )
                              }
                              }
                            />
                            </div>
                            <TextField
                              id="outlined-basic"
                              label="example@domain.com"
                              variant="outlined"
                              value={newEmail[item.id] ?? ''}
                              onChange={(e) => handleChange({email: e.target.value, factoryId: item.id})}
                              {...(newEmail[item.id] && !newEmail[item.id].match(emailRegex) && {error: true})}
                              {...(newEmail[item.id] && !newEmail[item.id].match(emailRegex) && {helperText: 'E-mail address should have one "@" sign.'})}
                            />
                            <Button variant="contained"
                                    disabled={!(newEmail[item.id] && newEmail[item.id].match(emailRegex)) || !(checked[item.id]?.ok || checked[item.id]?.nok)}
                                    style={{height: '56px'}}
                                    onClick={() => {
                                      let subscription = {
                                        oascode: item.oasCode.code,
                                        ok: checked[item.id]?.ok,
                                        nok: checked[item.id]?.nok,
                                        email: newEmail[item.id]
                                      };
                                      const existingSub = subscriptions.find(sub => sub.email === newEmail[item.id] && sub.oascode === item.oasCode.code);
                                      if (existingSub?.ok) {
                                        subscription.ok = true;
                                      }
                                      if (existingSub?.nok) {
                                        subscription.nok = true;
                                      }
                                      createNewSubscription({
                                        subscription,
                                        id: item.id
                                      })
                                    }}>
                              Add
                            </Button>
                            {updating && <span style={{margin: '0 10px 0 16px'}}>Loading <Loader size={16} /></span>}
                            {createError && <Alert onClose={() => {setCreateError(undefined)}} severity="error">An error occurred when creating subscription {createError?.response?.data?.substring(0, 140)}.</Alert>}
                            {deleteError && <Alert onClose={() => {setDeleteError(undefined)}} severity="error">An error occurred when removing subscription {deleteError?.response?.data?.substring(0, 140)}.</Alert>}
                          </div>
                          <div className={'emails-grid'}>
                            <div style={{padding: '0 30px'}}>
                              <h6 style={{fontWeight: 700}}>ok</h6>
                              <Table className={'emails-table'} borderless
                                     style={{marginBottom: 0, display: 'block', fontSize: '14px'}}>
                                <tbody style={{display: 'table', width: '100%'}}>
                                <tr>
                                  <th style={{textAlign: 'left'}}>Currently subscribed</th>
                                </tr>
                                <tr key={item.id}>
                                  <td style={{
                                    textAlign: 'left',
                                    verticalAlign: 'middle',
                                    paddingTop: '16px',
                                    paddingBottom: '16px'
                                  }}>
                                    <div className={'emails-wrapper'}>
                                      {subscriptions.length && subscriptions.filter(sub => sub.oascode === item.oasCode.code && sub.ok).length ? subscriptions.filter(sub => sub.oascode === item.oasCode.code && sub.ok).map(sub => {
                                        return <Chip key={sub.email} label={sub.email} variant="outlined"
                                                     onDelete={() => handleDelete({subscription: {
                                                       oascode: item.oasCode.code,
                                                       ok: sub.ok,
                                                       nok: sub.nok,
                                                       email: sub.email

                                                     }, removeFrom: 'ok'})} style={{margin: '6px 6px 6px 0'}}/>
                                      }) : null}
                                    </div>
                                  </td>
                                </tr>

                                </tbody>
                              </Table>
                            </div>
                            {/**************************************************/}
                            <div style={{padding: '0 30px', borderLeft: '1px solid rgba(0, 0, 0, 0.175)'}}>
                              <h6 style={{fontWeight: 700}}>nok</h6>
                              <Table className={'emails-table'} borderless
                                     style={{marginBottom: 0, display: 'block', fontSize: '14px'}}>
                                <tbody style={{display: 'table', width: '100%'}}>
                                <tr>
                                  <th style={{textAlign: 'left'}}>Currently subscribed</th>
                                </tr>
                                <tr key={item.id}>
                                  <td style={{
                                    textAlign: 'left',
                                    verticalAlign: 'middle',
                                    paddingTop: '16px',
                                    paddingBottom: '16px'
                                  }}>
                                    <div className={'emails-wrapper'}>
                                      {subscriptions.length && subscriptions.filter(sub => sub.oascode === item.oasCode.code && sub.nok).length ? subscriptions.filter(sub => sub.oascode === item.oasCode.code && sub.nok).map(sub => {
                                        return <Chip key={sub.email} label={sub.email} variant="outlined"
                                                     onDelete={() => handleDelete({subscription: {
                                                       oascode: item.oasCode.code,
                                                       ok: sub.ok,
                                                       nok: sub.nok,
                                                       email: sub.email

                                                     }, removeFrom: 'nok'})} style={{margin: '6px 6px 6px 0'}}/>
                                      }) : null}
                                    </div>
                                  </td>
                                </tr>

                                </tbody>
                              </Table>
                            </div>
                          </div>
                        </div>
                      </AccordionDetails>
                    </Accordion>

                )}

              </>}
            </CardBody>
          </Card>
        </div>
      </div>
      {subscriptionToBeDeleted ? <Modal
        open={!!subscriptionToBeDeleted}
        onClose={() => {
          setSubscriptionToBeDeleted(undefined)
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h5" id="modal-modal-title" component="h2" style={{marginBottom: '10px'}}>
            Remove subscription
          </Typography>
          <p>Are you sure that you want to remove
            subscription of {subscriptionToBeDeleted.subscription.email} to {`${subscriptionToBeDeleted.removeFrom}`} results for factory {`${subscriptionToBeDeleted.subscription.oascode}`}?</p>
          <div style={{display: 'flex', justifyContent: 'end'}}>
            <Button onClick={() => {
              setSubscriptionToBeDeleted(undefined)
            }} className={'no-underline'}>cancel</Button>
            <Button onClick={() => {
              if (subscriptionToBeDeleted) {
                deleteRequest(subscriptionToBeDeleted);
                setSubscriptionToBeDeleted(undefined)
              }
            }} className={'no-underline'} variant="contained">delete</Button>
          </div>
        </Box>
      </Modal> : null}
    </Page>
  );
}