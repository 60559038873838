import React, {useContext, useEffect, useMemo, useState} from "react";
import {
  MsalAuthenticationTemplate,
  useAccount,
  useMsal
} from "@azure/msal-react";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import {FactoryEdit} from "./FactoryEdit";
import Administration from "./Administration";
import {EmailNotifications} from "./EmailNotifications";
import Configuration from "./Configuration";
import {FilesDownload} from "./FilesDownload";
import Tools from "./Tools";
import Reports from "./Reports";
import Page from "./Page";
import {IncomingOrders} from "./IncomingOrders";
import {BodyOrderHistory} from "./BodyOrderHistory";
import {MasterIDPassword} from "./MasterIDPassword";
import Home from "./Home";
import {Factories} from "./Factories";
import useAxios, {UseAxiosResult} from "axios-hooks";
import './App.css';
import {bspMSALContext} from "./apiCalls";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import locale from "date-fns/locale/en-GB";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {ThemeProvider} from "@mui/styles";
import {createTheme} from "@mui/material";
import {InteractionType} from "@azure/msal-browser";
import {NoPermissions} from "./NoPermissions";
import {Roles} from "./Roles";

const environment = process.env.REACT_APP_ENVIRONMENT

interface UserData {
  email: string;
  isSubscribed: boolean;
  refreshTime: number;
  resources: string[];
  userName: string;
}

interface PermissionsMapping {
  [key: string]: string;
}

const permissionsMapping: PermissionsMapping = {
  BodyOrderHistory: 'page_chassishistory',
  Factories: 'page_factories',
  FilesDownload: 'page_filedownload',
  MasterIDPassword: 'page_getmasterpsw',
}

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */
const AppRoutes = () => {
  // const { setGraphData } = useContext(GraphDataContext);
  const { setUserData } = useContext(UserInfoContext);
  // const [{ data }] = useAxios(graphConfig.graphMeEndpoint);

  const [{
    data: userData,
    loading: userLoading,
  }]: UseAxiosResult<UserData | undefined, boolean, any> = useAxios('/user/info');

  // useEffect(() => {
  //   setGraphData(data);
  // }, [data, setGraphData])

  useEffect(() => {
    setUserData(userData);
  }, [userData, setUserData])

  const checkPermissions = (componentName: string, Component: JSX.Element) => {
    if (userData?.resources?.includes('full_access') || userData?.resources?.includes(permissionsMapping[componentName])) {
      return Component;
    } else {
      return <NoPermissions pageName={componentName} />
    }
  }

  return (
    userLoading ?
      <LoadingComponent />
    :
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="reports" element={<Reports/>} />
          <Route path="page" element={<Page/>} />
          <Route path="tools" element={<Tools/>} />
          <Route path="administration" element={<Administration/>} />
          <Route path="configuration" element={<Configuration/>} />
          <Route path="body-order-history" element={checkPermissions('BodyOrderHistory', <BodyOrderHistory />)} />
          {/*<Route path="files-download" element={checkPermissions('FilesDownload', <FilesDownload />)} />*/}
          <Route path="files-download" element={<FilesDownload />} />
          <Route path="master-id-password" element={checkPermissions('MasterIDPassword', <MasterIDPassword />)} />
          <Route path="incoming-orders" element={<IncomingOrders/>} />
          <Route path="factories" element={checkPermissions('Factories', <Factories />)} />
          <Route path="factory-edit" element={checkPermissions('Factories', <FactoryEdit />)} />
          <Route path="roles" element={checkPermissions('Roles', <Roles />)} />
          <Route path="email-notifications" element={<EmailNotifications/>} />
        </Routes>
      </BrowserRouter>
  );
};

const theme = createTheme();

const ErrorComponent = () => {
  return (
    <>
      <h1>Error while authenticating.</h1>
    </>
  );
};

const LoadingComponent = () => {
  return (
    <>
      <div className={'sign-in-container'}>
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </>
  );
};

const MainContent = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);

  bspMSALContext.account = account;
  bspMSALContext.instance = instance;

    return (
        <div className={`app-${environment}`}>
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent}
          >
              <ThemeProvider theme={theme}>
                <AppRoutes />
              </ThemeProvider>
          </MsalAuthenticationTemplate>
        </div>
    );
};

interface AppContext { // todo
  graphData: any;
  setGraphData: any;
}

const graphDataContextDefault = {
  graphData: null,
  setGraphData: () => {},
};

export interface UserContext { // todo
  userData: any;
  setUserData: any;
}

const userDataContextDefault = {
  userData: {
    email: '',
    isSubscribed: false,
    refreshTime: 0,
    resources: [],
    userName: ''
  },
  setUserData: () => {},
};

export const GraphDataContext = React.createContext<AppContext>(graphDataContextDefault);
export const UserInfoContext = React.createContext<UserContext>(userDataContextDefault);

export default function App() {
  const [graphData, setGraphData] = useState(graphDataContextDefault);
  const [userData, setUserData] = useState(userDataContextDefault);
  const value = useMemo(
    () => ({ graphData, setGraphData }),
    [graphData]
  );
  const userInfo = useMemo(
    () => ({ userData, setUserData }),
    [userData]
  );

  return (
    <GraphDataContext.Provider value={value}>
      <UserInfoContext.Provider value={userInfo}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
          <MainContent />
        </LocalizationProvider>
      </UserInfoContext.Provider>
    </GraphDataContext.Provider>
  );
}
