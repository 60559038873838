import React, {useContext, useEffect, useState} from "react";
import './IncomingOrders.css';
import {Card, CardBody, CardTitle} from "reactstrap";
import useAxios, {UseAxiosResult} from "axios-hooks";
import Page from "./Page";
import {Autocomplete, IconButton, TextField} from "@mui/material";

import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import {format, isValid, parse, parseISO} from "date-fns";
import {MobileDatePicker} from '@mui/x-date-pickers';
import {ChassisDTO} from "./BodyOrderHistory";
import {getUniqueListBy} from "./utils";
import {NoPermissions} from "./NoPermissions";
import {Link, UNSAFE_NavigationContext, useSearchParams} from "react-router-dom";
import {styles} from "./Home";

interface IncomingOrderDTO {
  ChassisList: string
  chassisNo: number;
  timestamp: string; // ISO 8601
  queueCorrelationId: string;
  transactionId: string;
  id: number;
}

interface ChassisListAutocomplete extends ChassisDTO {
  label: string;
}

const useBackListener = (callback: any) => { // todo duplicate in BOH
  const navigator = useContext(UNSAFE_NavigationContext).navigator;

  useEffect(() => {
    const listener = ({ location, action }: any) => {
      if (action === "POP") {
        callback({ location, action });
      }
    };

    const unlisten = (navigator as any).listen(listener);
    return unlisten;
  }, [callback, navigator]);
};

export const EMPTY_CHASSIS_ID = {
  chassisType: '',
  chassisNo: 0,
}

interface DateParams {
  from: string;
  to: string;
}

type SearchParams = ChassisDTO & DateParams;

export function IncomingOrders() {
  let searchParamsObject = Object.fromEntries(new URLSearchParams(window.location.search).entries()) as unknown as SearchParams;
  let validSearchParamsObject: ChassisDTO = EMPTY_CHASSIS_ID;
  if (searchParamsObject['chassisType'] && searchParamsObject['chassisNo']) {
    validSearchParamsObject = {
      chassisType: searchParamsObject['chassisType'],
      chassisNo: searchParamsObject['chassisNo']
    };
  }
  let initialValue = '';
  if (validSearchParamsObject.chassisNo && validSearchParamsObject.chassisNo !== 0) {
    initialValue = `${validSearchParamsObject.chassisType} ${validSearchParamsObject.chassisNo}`;
  }
  let initialFromDate = null;
  let initialToDate = null;
  try {
    let parsedFrom = parse(searchParamsObject.from, 'yyyy-MM-dd', new Date());
    if (!isValid(parsedFrom)) {
      throw new Error('invalid from date');
    } else {
      initialFromDate = parsedFrom;
    }
  }
  catch {
    // console.error('incorrect from date format')
  }
  try {
    let parsedTo  = parse(searchParamsObject.to, 'yyyy-MM-dd', new Date());
    if (!isValid(parsedTo)) {
      throw new Error('invalid to date');
    } else {
      initialToDate = parsedTo;
    }
  }
  catch {
    // console.error('incorrect to date format')
  }

  const [pageSize, setPageSize] = useState(5);
  const [fromDate, setFromDate] = useState<Date | null>(initialFromDate);
  const [toDate, setToDate] = useState<Date | null>(initialToDate);
  const [currentChassis, setCurrentChassis] = useState<ChassisDTO>(validSearchParamsObject);
  const [chassisList, setChassisList] = useState<ChassisListAutocomplete[]>([]);
  const [{ data, loading, error }, fetchOrders]: UseAxiosResult<IncomingOrderDTO[] | undefined, boolean, any> = useAxios(
    `/orders/getorderlist?chassisType=${currentChassis?.chassisType.trim()}&chassisNo=${currentChassis?.chassisNo}${fromDate ? `&from=${format(fromDate, "yyyy-MM-dd")}` : ''}${toDate ? `&to=${format(toDate, "yyyy-MM-dd")}` : ''}`, { manual: true }
  );
  const [{ data: dataChassisList, loading: loadingChassisList, error: errorChassisList }]: UseAxiosResult<ChassisDTO[] | undefined, boolean, any> = useAxios(`/orders/getchassistype`);
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const newChassisType = searchParams.get('chassisType');
    const newChassisNo = searchParams.get('chassisNo');
    const newFromDate = searchParams.get('from');
    const newToDate = searchParams.get('to');
    if (newChassisType && newChassisNo) {
      setCurrentChassis({
        chassisType: newChassisType,
        chassisNo: parseInt(newChassisNo, 10),
      })
    }
    if (newFromDate) {
      try {
        setFromDate(parse(newFromDate, 'yyyy-MM-dd', new Date()))
      }
      catch {
        console.error('incorrect from date format')
      }
    }
    if (newToDate) {
      try {
        setToDate(parse(newToDate, 'yyyy-MM-dd', new Date()))
      }
      catch {
        console.error('incorrect to date format')
      }
    }
  }, [searchParams])

  useBackListener(({ location }: any) => {
      if (location.search.indexOf('chassisNo') === -1 || location.search.indexOf('chassisType') === -1) {
        setCurrentChassis(EMPTY_CHASSIS_ID);
      }
      if (location.search.indexOf('from') === -1) {
        setFromDate(null);
      }
      if (location.search.indexOf('to') === -1) {
        setToDate(null);
      }
    }
  );

  useEffect( () => {
    if (currentChassis) {
      fetchOrders();
    }
  }, [currentChassis, fromDate, toDate, fetchOrders])

  useEffect(() => {
    if (dataChassisList) {
      setChassisList([
        ...getUniqueListBy(dataChassisList.map(c => ({...c, label: `${c.chassisType} ${c.chassisNo}`})), 'label'),
        {
          ...EMPTY_CHASSIS_ID,
          label: ''
        }
      ]);
    }
  }, [dataChassisList])

  const columns = [
    {
      field: 'firstName',
      headerName: 'Chassis',
      width: 150,
      renderCell: (params: any) =>
        <Link
          style={{...styles.link, padding: 0}}
          to={`/body-order-history?chassisType=${params.row.chassisType}&chassisNo=${params.row.chassisNo}#${params.row.id}`}
          title={`See ${params.row.chassisType} ${params.row.chassisNo} on Body Order History page`}
        >
          {params.row.chassisType} {params.row.chassisNo}
        </Link>
    },
    {
      field: 'timestamp',
      headerName: 'Timestamp',
      width: 150,
      valueGetter: (params: any) => format(parseISO(params.row.timestamp), "yyyy-MM-dd HH:mm")

    },
    {
      field: 'queueCorrelationId',
      headerName: 'Queue correlation ID',
      type: 'string',
      width: 430,
    },
    {
      field: 'transactionId',
      headerName: 'Transaction ID',
      width: 390,
    },
  ];

  const rowHeight = 51;

  return(
    error?.response?.status === 403 ||
    errorChassisList?.response?.status === 403
      ? <NoPermissions isFetchingResources /> :
    <Page title={'Incoming Orders'}>
      <div className="row">
        <div className="col-12 col-md-4 col-xl-3">
          <Card className={'chassis-selector'} style={{marginBottom: '30px'}}>
            <CardBody>
              <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
                Filtering
              </CardTitle>
                  <div>From date:</div>
                  <MobileDatePicker
                    DialogProps={{classes: {
                        container: 'calendar-container'
                      }}}
                    className={'full-width'}
                    inputFormat="yyyy-MM-dd"
                    mask="____-__-__"
                    label="From"
                    value={fromDate}
                    onChange={(newValue: any) => {
                      setFromDate(newValue);
                      searchParams.set('from', format(newValue, 'yyyy-MM-dd'));
                      setSearchParams(searchParams);
                    }}
                    renderInput={(params: any) => <TextField {...params} />}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={() => {
                          setFromDate(null);
                          searchParams.delete('from');
                          setSearchParams(searchParams);
                        }
                        }>
                          &#215;
                        </IconButton>
                      )
                    }}
                  />
                  <br/>
                  <div>To date:</div>
                  <MobileDatePicker
                    DialogProps={{classes: {
                        container: 'calendar-container'
                      }}}
                    className={'full-width'}
                    inputFormat="yyyy-MM-dd"
                    mask="____-__-__"
                    label="To"
                    value={toDate}
                    onChange={(newValue: any) => {
                      setToDate(newValue);
                      searchParams.set('to', format(newValue, 'yyyy-MM-dd'));
                      setSearchParams(searchParams);
                    }}
                    renderInput={(params: any) => <TextField {...params} />}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={() => {
                          setToDate(null);
                          searchParams.delete('to');
                          setSearchParams(searchParams);
                        }}>
                          &#215;
                        </IconButton>
                      )
                    }}
                  />
                  <br/>
                  <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
                    Chassis ID
                  </CardTitle>
                  {loadingChassisList ? <p>Loading...</p> : null}
                  {dataChassisList ?
                  <Autocomplete
                    value={
                      chassisList.find(
                        chassis => chassis.chassisNo === currentChassis?.chassisNo && chassis.chassisType === currentChassis.chassisType
                      )
                      ?? {
                        ...EMPTY_CHASSIS_ID,
                        label: `${EMPTY_CHASSIS_ID.chassisType} ${EMPTY_CHASSIS_ID.chassisNo}`
                      }
                    }
                    inputValue={value}
                    onInputChange={
                      (event: any, newValue: string | null) => {
                        if (newValue === null) {
                          setValue('')
                        }
                        else {
                          setValue(newValue.toUpperCase())
                        }
                      }
                    }
                    disablePortal
                    open
                    // sx={{ height: 400 }}
                    // ListboxProps={{ style: { height: "600px" } }}
                    id="combo-box-demo"
                    options={chassisList}
                    renderInput={(params) =>
                      <TextField {...params} label="Select or Search"/>
                    }
                    onChange={(event, value) => {
                      if (value) {
                        setSearchParams(Object.entries(value as ChassisDTO).filter(entry => ['chassisType', 'chassisNo'].includes(entry[0])));
                      } else {
                        setCurrentChassis(EMPTY_CHASSIS_ID)
                        searchParams.delete('chassisType');
                        searchParams.delete('chassisNo');
                        setSearchParams(searchParams);
                      }
                    }}
                  />
                    : null}
            </CardBody>
          </Card>

        </div><div className="col-12 col-md-8 col-xl-9">
          <Card className={'chassis-selector'}>
            <CardBody>
              {loading && <p>Loading...</p>}
              {error && <p>Error!!!</p>}
              {data?.length && !loading && !error ? <>
              <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
                Chassis ID
              </CardTitle>

                {data?.length ?
              <div style={{ height: `${(rowHeight*pageSize)+160}px`, width: '100%' }}>
                <DataGrid
                  rows={data}
                  columns={columns}
                  pageSize={pageSize}
                  disableSelectionOnClick
                  components={{ Toolbar: GridToolbar }}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  rowsPerPageOptions={[5, 10, 20]}
                />
              </div>
               : null
                }
              </> : null}
              {!data?.length && (fromDate || toDate) && currentChassis && currentChassis.chassisNo !== 0 && !loading && !error ?
                <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
                  No data for chassis {currentChassis?.chassisType} {currentChassis?.chassisNo} in given date range{fromDate ? ` from: ${format(fromDate, "yyyy-MM-dd")}` : ''} {toDate ? ` to: ${format(toDate, "yyyy-MM-dd")}` : ''}
                </CardTitle> : null}
              {!data?.length && (fromDate || toDate) && currentChassis && !currentChassis.chassisNo && !loading && !error ?
                <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
                  No data in given date range{fromDate ? ` from: ${format(fromDate, "yyyy-MM-dd")}` : ''} {toDate ? ` to: ${format(toDate, "yyyy-MM-dd")}` : ''}
                </CardTitle> : null}
              {!data?.length && !fromDate && !toDate && currentChassis && currentChassis.chassisNo !== 0 && !loading && !error ?
                <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
                  No data for chassis {currentChassis?.chassisType} {currentChassis?.chassisNo}
                </CardTitle> : null}
              {!data?.length && currentChassis && currentChassis.chassisNo !== 0 && !fromDate && !toDate && !loading && !error ?
                <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
                  No data
                </CardTitle> : null}
            </CardBody>
          </Card>
        </div>
      </div>
    </Page>
  );
}