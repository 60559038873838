import React, {useContext, useEffect, useState} from 'react';
import {Alert, List, ListItem} from '@mui/material';
import {Card, CardBody, CardTitle} from 'reactstrap';
import News from "./News";
import Notifications from "./Notifications";
import {History} from "./History";
import useAxios, {UseAxiosResult} from "axios-hooks";
import {format, parseISO} from 'date-fns'
import Page from "./Page";
import {Link} from "react-router-dom";
import {ChassisDTO} from "./BodyOrderHistory";
import './Home.css';
import {NoPermissions} from "./NoPermissions";
import {UserInfoContext} from "./App";
import {useInterval} from 'usehooks-ts'
import Loader from "./Loader";

interface LastProcessing extends ChassisDTO {
  timestamp: string; // ISO 8601
  id: number;
}

export const styles = {
  link: {
    fontSize: '0.875rem',
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    padding: '6px 8px',
    textDecoration: 'none',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  }
}

function Home() {

  const [{
    data,
    loading,
    error
  }, executeFetch]: UseAxiosResult<LastProcessing[] | undefined, boolean, any> = useAxios({url: '/stats/lastOK'}, {manual: true})
  const [{
    data: dataNOK,
    loading: loadingNOK,
    error: errorNOK
  }, executeFetchNOK]: UseAxiosResult<LastProcessing[] | undefined, boolean, any> = useAxios({url: '/stats/lastNOK'}, {manual: true})
  const {userData} = useContext(UserInfoContext);

  const [refreshTime, setRefreshTime] = useState<number | null>(userData?.refreshTime ?? null)

  useEffect(() => {
    executeFetchNOK();
    executeFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const interval = userData?.refreshTime;
    if (interval && interval !== 1) {
      setRefreshTime(interval)
    } else {
      setRefreshTime(null)
    }
  }, [userData?.refreshTime])

  useInterval(
    () => {
      executeFetchNOK();
      executeFetch();
    },
    refreshTime && typeof refreshTime === 'number' ? refreshTime * 1000 : null
  )

  return (
    error?.response?.status === 403 ? <NoPermissions isFetchingResources/> :
      <Page className={'home-container'} title={'Home: Information and Statistics'} subtitle={'Order Information'}
            refresh>
        <Card className={'last-ok'}>
          <CardBody>
            <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
              Last OK Processing
            </CardTitle>
            <List disablePadding={true}>
              {loading && <p>Loading <Loader size={12}/></p>}
              {error && <Alert severity="error">Error loading OK data.</Alert>}
              {!loading && data && data.length ? data.map((d: LastProcessing, index: number) =>
                <ListItem key={`${d.chassisNo}-${d.chassisType}-${d.timestamp}`}
                          style={{backgroundColor: index % 2 ? 'transparent' : '#F4F4F4'}} disablePadding={true}>
                  {format(parseISO(d.timestamp), "yyyy-MM-dd HH:mm")}<Link style={styles.link}
                                                                           to={`/body-order-history?chassisType=${d.chassisType}&chassisNo=${d.chassisNo}#${d.id}`}>{d.chassisType} {d.chassisNo}</Link>
                </ListItem>) : null}
              {data && !data?.length ? <p>No results.</p> : null}
            </List>
          </CardBody>
        </Card>
        <Card className={'last-nok'}>
          <CardBody>
            <CardTitle tag="h5" style={{fontSize: '18px', fontWeight: '700'}}>
              Last NOK Processing
            </CardTitle>
            <List disablePadding={true}>
              {loadingNOK && <p>Loading <Loader size={12}/></p>}
              {errorNOK && <Alert severity="error">Error loading NOK data.</Alert>}
              {!loadingNOK && dataNOK && dataNOK.length ? dataNOK.map((d: LastProcessing, index: number) =>
                <ListItem key={`${d.chassisNo}-${d.chassisType}-${d.timestamp}`}
                          style={{backgroundColor: index % 2 ? 'transparent' : '#F4F4F4'}}
                          disablePadding={true}>{format(parseISO(d.timestamp), "yyyy-MM-dd HH:mm")}
                  <Link style={styles.link}
                        to={`/body-order-history?chassisType=${d.chassisType}&chassisNo=${d.chassisNo}#${d.id}`}>{d.chassisType} {d.chassisNo}</Link>
                </ListItem>) : null}
              {dataNOK && !dataNOK?.length ? <p>No results.</p> : null}
            </List>
          </CardBody>
        </Card>
        <History/>
        <News/>
        <Notifications/>
      </Page>
  );
}

export default Home;
