import useAxios, {UseAxiosResult} from "axios-hooks";
import React, {useEffect} from "react";
import {Box, Modal, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {FactoryDTO} from "./Factories";

interface FactoryDeleteProps {
  factoryToBeDeleted: FactoryDTO;
  setFactoryToBeDeleted: (arg: undefined) => void;
  setFactories: (factories: FactoryDTO[]) => void;
  factories: FactoryDTO[];
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 660,
  maxHeight: '80vh',
  overflow: 'auto',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  outline: 0
};

export const FactoryDelete = ({
                                factoryToBeDeleted,
                                setFactoryToBeDeleted,
                                setFactories,
                                factories
                              }: FactoryDeleteProps) => {

  const [{
    data: deleteData,
    loading: deleteLoading,
    error: deleteError,
    response: deleteResponse
  }, executeDelete]: UseAxiosResult<any, any, any> = useAxios({
    url: '/factory/deleteFactory',
    method: 'DELETE'
  }, {manual: true});

  useEffect(() => {
    if (deleteResponse && factoryToBeDeleted !== null) {
      setFactories(factories.filter(n => n.id !== factoryToBeDeleted.id))
      setFactoryToBeDeleted(undefined);
    }
  }, [deleteResponse, factoryToBeDeleted, factories])

  return <Modal
    open={!!factoryToBeDeleted}
    onClose={() => {
      setFactoryToBeDeleted(undefined)
    }}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <Typography variant="h5" id="modal-modal-title" component="h2" style={{marginBottom: '10px'}}>
        Delete factory
      </Typography>
      <p>Are you sure that you want to remove
        factory {factoryToBeDeleted.factoryName} ({factoryToBeDeleted.description})?</p>
      <div style={{display: 'flex', justifyContent: 'end'}}>
        <Button onClick={() => {
          setFactoryToBeDeleted(undefined)
        }} className={'no-underline'}>cancel</Button>
        <Button onClick={() => {
        if (factoryToBeDeleted) {
          executeDelete({
            data: {
              id: factoryToBeDeleted.id
            }
          })
        }
      }} className={'no-underline'} variant="contained">delete</Button>
      </div>
    </Box>
  </Modal>
}
